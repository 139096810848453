
import {tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthentificationConstants} from '../authentication/authentification-constants';
import {AuthentificationTokenService} from '../authentication/authentification-token.service';
import {RestRoutes} from '../rest/rest-routes';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationRequestInterceptor implements HttpInterceptor {
    constructor(private authentificationTokenService: AuthentificationTokenService) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let newRequest = req.clone({headers: req.headers.set('Content-Type', 'application/json') });
        const currentUserInSessionStorage = this.authentificationTokenService.getToken();
        const insertTokenIntoRequest = req.url.includes(RestRoutes.LOGIN_CAS_URL) || req.url.includes(RestRoutes.VALIDATE_TICKET) || req.url.includes(RestRoutes.TRANSLATION);
        if (currentUserInSessionStorage && !insertTokenIntoRequest) {
            newRequest = newRequest.clone({
              headers: req.headers.set(AuthentificationConstants.HEADER_AUTH_TOKEN, this.authentificationTokenService.getToken())
            });
        }
        return next.handle(newRequest).pipe(tap(response => {
            if (response instanceof HttpResponse) {
                this.authentificationTokenService.enregistrerTokenDansStorage(
                  response.headers.get(AuthentificationConstants.HEADER_AUTH_TOKEN));
            }
            return response;
        }));
    }
}
