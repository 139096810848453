import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {GuiRoutes} from './gui-routes';
import {AccueilDesktopComponent} from './view/accueil/desktop/accueil-desktop.component';
import {DocumentsDesktopComponent} from './view/documents/desktop/documents-desktop.component';
import {NotesDesktopComponent} from './view/notes/desktop/notes-desktop.component';
import {LoginGuard} from './technique/guard/login/login.guard';
import {LoginComponent} from './view/login/login.component';
import {MainDesktopComponent} from './view/main/desktop/main-desktop.component';
import {ErreurDefautComponent} from './view/erreur-defaut/erreur-defaut.component';
import {ErreurLayoutComponent} from './view/erreur-layout/erreur-layout.component';
import {ReconnexionComponent} from './view/reconnexion/reconnexion.component';
import {PageNonTrouveeComponent} from './view/page-non-trouvee/page-non-trouvee.component';
import {ErreurConnexionRefuseeComponent} from './view/erreur-connexion-refusee/erreur-connexion-refusee.component';
import {PageInconnueComponent} from './view/page-inconnue/page-inconnue.component';
import {MobileGuard} from './technique/guard/mobile/mobile.guard';
import {MainMobileComponent} from './view/main/mobile/main-mobile.component';
import {DesktopGuard} from './technique/guard/desktop/desktop.guard';
import {DocumentsMobileComponent} from './view/documents/mobile/documents-mobile.component';
import {AccueilMobileComponent} from './view/accueil/mobile/accueil-mobile.component';
import {NotesMobileComponent} from './view/notes/mobile/notes-mobile.component';
import {InfosDesktopComponent} from './view/infos/desktop/infos-desktop.component';
import {InfosMobileComponent} from './view/infos/mobile/infos-mobile.component';

const routes: Routes = [
  {
    path: GuiRoutes.ROOT,
    component: LoginComponent,
    canActivate: [LoginGuard],
    canActivateChild : [LoginGuard],
    children: [
      {
        path: GuiRoutes.ROOT,
        component: MainDesktopComponent,
        canActivate: [DesktopGuard],
        canActivateChild : [DesktopGuard],
        children : [
          { path: GuiRoutes.ROOT, component: AccueilDesktopComponent },
          { path: GuiRoutes.ACCUEIL, component: AccueilDesktopComponent },
          { path: GuiRoutes.DOCUMENTS, component: DocumentsDesktopComponent },
          { path: GuiRoutes.NOTES, component: NotesDesktopComponent },
          { path: GuiRoutes.INFOS, component: InfosDesktopComponent }
        ]
      },
      {
        path: GuiRoutes.MOBILE,
        component: MainMobileComponent,
        canActivate: [MobileGuard],
        canActivateChild: [MobileGuard],
        children : [
          { path: GuiRoutes.ROOT, component: AccueilMobileComponent },
          { path: GuiRoutes.ACCUEIL, component: AccueilMobileComponent },
          { path: GuiRoutes.DOCUMENTS, component: DocumentsMobileComponent },
          { path: GuiRoutes.NOTES, component: NotesMobileComponent },
          { path: GuiRoutes.INFOS, component: InfosMobileComponent }
        ]
      }
    ]
  },
  {
    path: GuiRoutes.ROOT,
    component: ErreurLayoutComponent,
    children: [
      {path: GuiRoutes.ERREUR, component: ErreurDefautComponent},
      {path: GuiRoutes.RECONNEXION, component: ReconnexionComponent},
      {path: GuiRoutes.PAGE_INCONNUE, component: PageInconnueComponent},
      {path: GuiRoutes.CONNEXION_REFUSEE, component: ErreurConnexionRefuseeComponent}
    ]
  },
  {
    path: GuiRoutes.ALL_OTHERS,
    component: PageNonTrouveeComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
