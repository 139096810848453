import {Component} from '@angular/core';
import {AccueilComponent} from '../accueil-component';
import {ComponentsInfosService} from '../../../service/components-infos.service';
import {UrlConverter} from '../../../technique/url/url-converter';
import {Router} from '@angular/router';

@Component({
  selector: 'app-accueil-mobile',
  templateUrl: './accueil-mobile.component.html',
  styleUrls: ['./accueil-mobile.component.scss']
})
export class AccueilMobileComponent extends AccueilComponent {

  constructor(protected componentInfoService: ComponentsInfosService, protected router: Router) {
    super(componentInfoService);
  }

  public clickEvent(targetPath: string): void {
    this.router.navigate(UrlConverter.desktopUrlToMobileRoute(targetPath));
  }
}
