import {Injectable} from '@angular/core';
import PNotify from 'pnotify/dist/es/PNotify';
import PNotifyButtons from 'pnotify/dist/es/PNotifyButtons';
import PNotifyHistory from 'pnotify/dist/es/PNotifyHistory';
import PNotifyMobile from 'pnotify/dist/es/PNotifyMobile';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class NotificationService {

  constructor(protected translate: TranslateService) {}

  private getPNotify() {
    PNotifyButtons; // Initialise le module pour avoir les boutons pause et fermeture
    PNotifyHistory; // Initialise le module d'historique.
    PNotifyMobile; // initialise le module d'adaptation aux terminaux mobiles
    // PNotify.modules.History.defaults.maxInStack = 10; //Ne semble pas marcher dans la version actuelle, à voir avec les prochaines maj
    return PNotify;
  }

  success(message: string, title?: string) {
    this.getPNotify().success({title: title ? title : this.translate.instant('notification.titre.succes'), text: message});
  }

  error(message: string, title?: string) {
    this.getPNotify().error({title: title ? title : this.translate.instant('notification.titre.erreur'), text: message});
  }

  notice(message: string, title?: string) {
    this.getPNotify().notice({title: title ? title : this.translate.instant('notification.titre.avertissement'), text: message});
  }

  info(message: string, title?: string) {
    this.getPNotify().info({title: title ? title : this.translate.instant('notification.titre.information'), text: message});
  }

  showLast() {
    this.getPNotify().modules.History.showLast();
  }

  showAll() {
    this.getPNotify().modules.History.showAll();
  }
}
