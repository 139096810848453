import {Injectable} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {ConfirmationDialogComponent} from './confirmation-dialog/confirmation-dialog.component';
import {Observable} from 'rxjs';
import {AlertDialogComponent} from './alert-dialog/alert-dialog.component';

@Injectable()
export class MessageDialogService {

  constructor(private dialog: MatDialog) {
  }

  public openConfirmationDialog(
    title: string,
    message: string,
    cancelButtonLabel: string,
    confirmButtonLabel: string,
    width?: string,
    height?: string
  ): Observable<any> {

    const dialogRef = this.dialog.open(ConfirmationDialogComponent,
      {
        disableClose: true,
        width: width ? width : '',
        height: height ? height : '',
        position: {top: '50px'},
        panelClass: 'surcharge-dialog', // déclaration de la classe faite dans sangria.css
        data: {title, message, cancelButtonLabel, confirmButtonLabel}
      });

    return dialogRef.afterClosed();
  }

  public openAlertDialog(
    title: string,
    message: string,
    buttonLabel: string,
    width?: string,
    height?: string
  ): Observable<any> {

    const dialogRef = this.dialog.open(AlertDialogComponent,
      {
        disableClose: true,
        width: width ? width : '',
        height: height ? height : '',
        position: {top: '50px'},
        panelClass: 'surcharge-dialog',
        data: {title, message, buttonLabel}
      });

    return dialogRef.afterClosed();
  }

}
