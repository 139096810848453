import {MenuInfo} from '../../models/menu-info';
import { OnInit, Directive } from '@angular/core';
import {Etudiant} from '../../models/etudiant';
import {User} from '../../models/user';
import {catchError, tap} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {Router} from '@angular/router';
import {EtudiantService} from '../../service/etudiant.service';
import {SignoutService} from '../../service/signout.service';
import {ComponentsInfosService} from '../../service/components-infos.service';
import {TranslateService} from '@ngx-translate/core';
import {MenuItemInfo} from '../drop-down-menu/menu-item-info';
import {RouteService} from '../../technique/routes/route.service';
import { ItemExtended, IUser } from 'ng-cocktail-ui';

@Directive()
export class MainComponent implements OnInit {

  user: IUser;
  menu: Array<ItemExtended>;
  protected readonly menuItemDeconnexion: MenuItemInfo;
  protected readonly menuItemInfos: MenuItemInfo;
  protected readonly menuItemRetour: MenuItemInfo;

  constructor(
    protected router: Router,
    protected etudService: EtudiantService,
    protected signoutService: SignoutService,
    protected menuInfoService: ComponentsInfosService,
    protected translate: TranslateService,
    protected routeService: RouteService) {
    this.menu = new Array<ItemExtended>();
    this.user = new User();
    this.menuItemDeconnexion = new MenuItemInfo().setLabelKey('menu.deroulant.deconnexion').setIcon('sign-out-alt');
    this.menuItemInfos = new MenuItemInfo().setLabelKey('menu.deroulant.infos').setIcon('id-card-alt');
    this.menuItemRetour = new MenuItemInfo().setLabelKey('menu.deroulant.retour').setIcon('times');
  }

  private static createMenuItem(menuInfo: MenuInfo): ItemExtended {
    const menuItem = new ItemExtended(1);
    menuItem.label = menuInfo.libelle;
    menuItem.name = menuInfo.id;
    menuItem.path = menuInfo.targetPath;
    menuItem.icon = menuInfo.iconName;
    menuItem.color = menuInfo.cssClass;
    return menuItem;
  }

  private getMenuInfoAccueil(): MenuInfo {
      return new MenuInfo('accueil', this.translate.instant('menu.accueil.label'), null, 'accueil', '', 'fas fa-home');
  }

  public ngOnInit(): void {
    this.refreshUser();
    this.getMenuInfos();
  }

  public refreshUser(): void {
    this.etudService
      .getEtudiant()
      .pipe(
        tap((etudiant: Etudiant) => {
          this.user.fullname = etudiant.prenom.concat(' ', etudiant.nom);
        }),
        catchError(err => throwError(new Error(err.error.message)))
      )
      .subscribe();
  }

  public getMenuInfos(): void {
    this.menuInfoService.refreshComponentInfos().subscribe(
      (menuInfos: Array<MenuInfo>) => {
        new Array<MenuInfo>(this.getMenuInfoAccueil(), ...menuInfos)
          .forEach(
            (sideMenuInfo: MenuInfo) => {
              this.menu.push(MainComponent.createMenuItem(sideMenuInfo));
            });
      },
      (err) => {
        console.log(err.message);
      }
    );
  }

}
