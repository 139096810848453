import { Component, OnInit } from '@angular/core';
import {FrontErrorService} from '../../technique/error/front-error.service';
import {FrontErrorUnknownPage} from '../../technique/error/errors/front-error-unknown-page';

@Component({
  selector: 'app-page-inconnue',
  templateUrl: './page-non-trouvee.component.html',
  styleUrls: ['./page-non-trouvee.component.scss']
})
export class PageNonTrouveeComponent implements OnInit {

  constructor(private frontErrorService: FrontErrorService) { }

  public ngOnInit(): void {
    this.frontErrorService.handleError(new FrontErrorUnknownPage());
  }

}
