import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, share} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {RestRoutes} from '../technique/rest/rest-routes';
import {plainToClass} from 'class-transformer';
import {InfoApplication} from '../models/info-application';
import {BackendHttpClient} from '../technique/http-client/backend-http-client.service';

@Injectable({
    providedIn: 'root'
})
export class InfoApplicationService {

    constructor(private http: BackendHttpClient) {}

    public getInfoApplication(): Observable<InfoApplication> {
        return this.http.get<InfoApplication>(RestRoutes.APPLICATION_INFOS);
    }
}
