import {Component, OnInit} from '@angular/core';
import {InfosComponent} from '../infos-component';
import {ActivatedRoute, Params} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {EtudiantService} from '../../../service/etudiant.service';
import {ContactService} from 'src/app/service/contact.service';
import {Adresse} from 'src/app/models/adresse';
import {Telephone} from 'src/app/models/telephone';
import { MatDialog } from '@angular/material/dialog';




@Component({
  selector: 'app-infos',
  templateUrl: './infos-desktop.component.html',
  styleUrls: ['./infos-desktop.component.scss']
})
export class InfosDesktopComponent extends InfosComponent implements OnInit {


  displayedColumnsAdresses: string[] = ['principal', 'codeTypeAdresse', 'adresse1', 'adresse2', 'boitePostale', 'codePostal', 'ville', 'pays', 'actions'];
  displayedColumnsContact: string[] = ['principal', 'typeMoyenCommunication', 'donneesRenseignees', 'actions'];
 

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected translateService: TranslateService,
    protected etudiantService: EtudiantService,
    protected contactService: ContactService,
    public dialog: MatDialog
  ) {
    super(activatedRoute, translateService, etudiantService, contactService, dialog);
  }

}
