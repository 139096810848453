import {GuiRoutes} from '../../gui-routes';

export class UrlConverter {

  public static from(desktopUrl: string): string {
    return GuiRoutes.MOBILE.concat(desktopUrl);
  }

  public static desktopRouteToMobileRoute(route: Array<string>): Array<string> {
    route.unshift(GuiRoutes.MOBILE);
    return route;
  }

  public static desktopUrlToMobileRoute(desktopUrl: string): Array<string> {
    return UrlConverter.desktopRouteToMobileRoute(UrlConverter.urlToRoute(desktopUrl));
  }

  public static desktopUrlToMobileUrl(desktopUrl: string): string {
    return GuiRoutes.MOBILE.concat('/').concat(desktopUrl);
  }

  public static urlToRoute(url: string): Array<string> {
    return url.split('/').filter(s => s);
  }

}
