export class FrontErrorMessages {

  private title: string;
  private message: string;
  private hints: string ;

  public getTitle(): string {
    return this.title;
  }

  public setTitle(title: string): FrontErrorMessages {
    this.title = title;
    return this;
  }

  public getMessage(): string {
    return this.message;
  }

  public setMessage(message: string): FrontErrorMessages {
    this.message = message;
    return this;
  }

  public getHints(): string {
    return this.hints;
  }

  public setHints(hints: string): FrontErrorMessages {
    this.hints = hints;
    return this;
  }

}
