import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BackendHttpClient} from '../http-client/backend-http-client.service';
import {RestRoutes} from '../rest/rest-routes';
import {TranslateResponse} from './translate-response';
import {LanguageNameResponse} from './language-name-response';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  constructor(private backendHttpClient: BackendHttpClient) {}

  public getTranslation(lang: string): Observable<TranslateResponse> {
    return this.backendHttpClient.get(RestRoutes.TRANSLATION, {params: {lang}});
  }

  public getLanguageNameForCode(langCodeSource: string, langCodeTarget: string): Observable<LanguageNameResponse> {
    return this.backendHttpClient.get(RestRoutes.LANGUAGE_FOR_CODE, {params: {langCodeSource, langCodeTarget}});
  }

}
