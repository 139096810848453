import { OnInit, Directive } from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {EtudiantService} from '../../service/etudiant.service';
import {ContactService} from '../../service/contact.service';
import {catchError, concatMapTo} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {Contact} from '../../models/contact';
import {Adresse} from 'src/app/models/adresse';
import {ContactInfos} from 'src/app/models/contact-infos';
import {Telephone} from 'src/app/models/telephone';
import {Compte} from 'src/app/models/compte';
import { IndividuEtudiantInfos } from 'src/app/models/individu-etudiant-infos';
import { MatDialog } from '@angular/material/dialog';
import { AdresseDialogComponent } from './dialog/dialog-adresse.component';
import { ParametreContact } from 'src/app/models/parametre-contact';
import { TelephoneDialogComponent } from './dialog/telephone/dialog-telephone.component';
import { EmailDialogComponent } from './dialog/email/dialog-email.component';

@Directive()
export class InfosComponent implements OnInit {

    modifierCoordoneesPersonnelles: boolean;
    iconKey: string;
    titleKey: string;
    etudiant: IndividuEtudiantInfos;
    adresses: Array<Adresse>;
    contactInfos: Array<ContactInfos>;
    telephonesMobileEtudiant: Array<Telephone>;
    adressePrincipale : Adresse;
    constructor(protected activatedRoute: ActivatedRoute,
                protected translateService: TranslateService,
                protected etudiantService: EtudiantService,
                protected contactService: ContactService,
                public dialog: MatDialog
    ) {
    }
  
    
    public ngOnInit(): void {
      this.iconKey = 'icon.MES_INFOS';
      this.titleKey = 'title.MES_INFOS';

      this.getIndividuEtudiantInfos().subscribe((individuEtudiantInfos: IndividuEtudiantInfos) => {
        this.etudiant = individuEtudiantInfos;
        this.getContact().subscribe(
          (contact: Contact) => {
            this.adresses = this.searchAdressesEtudiantEtParent(contact.adresses);
            this.transformToTelephoneInfo(contact);
            this.telephonesMobileEtudiant = this.searchTelephonesMobileEtudiant(contact.telephones);
          }
        )
        this.getParametresContact().subscribe(
          (parametreContact: ParametreContact) => {
            this.modifierCoordoneesPersonnelles = parametreContact.modifierCoordoneesPersonnelles;
          }
        )
      });
    }

    public getIndividuEtudiantInfos(): Observable<IndividuEtudiantInfos> {
      return this.etudiantService
        .getInformations()
        .pipe(catchError(err => throwError(new Error(err.error.message))));
    }

    public getContact(): Observable<Contact> {
      return this.contactService
        .getContact()
        .pipe(catchError(err => throwError(new Error(err.error.message))));
    }

    public getParametresContact(): Observable<ParametreContact> {
      return this.contactService
        .getParametresContact()
        .pipe(catchError(err => throwError(new Error(err.error.message))));
    }

    public searchAdressesEtudiantEtParent(adresses) {

      return adresses.filter((a: Adresse) => a.codeTypeAdresse === 'ETUD' || a.codeTypeAdresse === 'PAR');
    }

    public searchTelephonesMobileEtudiant(telephones) {
      return telephones.filter((t: Telephone) => t.typeNo == 'MOB' && t.typeTel == 'ETUD' && t.persId == this.etudiant.individuInfos.persId);
    }

    public transformToTelephoneInfo(contact) {
      this.contactInfos = new Array<ContactInfos>();
      let telephonesMobileEtudiant = this.searchTelephonesMobileEtudiant(contact.telephones);
      telephonesMobileEtudiant.forEach(tel => {
        this.contactInfos.push(new ContactInfos().setTypeMoyenCommunication("telephone").setDonneesRenseignees("(+"+ tel.indicatif +") " + tel.noTelephone).setPrincipal(false));
      });

      this.adressePrincipale = contact.adresses.find((a: Adresse) => a.principale);
      this.contactInfos.push(new ContactInfos().setTypeMoyenCommunication("email").setDonneesRenseignees(this.adressePrincipale.email).setPrincipal(false));
      contact.comptes.forEach(c => {
        this.contactInfos.push(new ContactInfos().setTypeMoyenCommunication("emailEtablissement").setDonneesRenseignees(c.email + "@" + c.domaine).setPrincipal(false));
      });
    }

    openDialog(adresse: Adresse) {
      const dialogRef = this.dialog.open(AdresseDialogComponent , {
        maxWidth: '100vw',
      maxHeight: '100vh',
      width: '75em',
      height: 'auto',
      });
      let instance = dialogRef.componentInstance;
      instance.adresse = adresse;
      dialogRef.afterClosed().subscribe(result => {
      });
    }

    afficherBoutonModifierContact(contactInfos : ContactInfos) {
      if (contactInfos.getTypeMoyenCommunication() == 'telephone' || contactInfos.getTypeMoyenCommunication() == 'email') {
        return this.modifierCoordoneesPersonnelles;
      } else {
        return false;
      }
    }

    openDialogContactInfos(contactInfos : ContactInfos) {
      if (contactInfos.getTypeMoyenCommunication() == 'telephone') {
        this.openDialogTelephone(contactInfos);
      } else if(contactInfos.getTypeMoyenCommunication() == 'email') {
        this.openDialogEmail(contactInfos);
      }
    }
    
    openDialogTelephone(contactInfos : ContactInfos){
      let telephoneSplitArray = contactInfos.getDonneesRenseignees().split(" ");
      if(telephoneSplitArray.length > 0) {
        let telephoneSelectionne = this.telephonesMobileEtudiant.find((t: Telephone) => t.noTelephone == telephoneSplitArray[1]);

        const dialogRef = this.dialog.open(TelephoneDialogComponent , {
          maxWidth: '100vw',
          maxHeight: '100vh',
          width: '75em',
          height: 'auto',
        });
        let instance = dialogRef.componentInstance;
        instance.telephone = telephoneSelectionne;
        instance.contactInfos = contactInfos;
        dialogRef.afterClosed().subscribe(result => {
        });
      }
    }

    openDialogEmail(contactInfos : ContactInfos){
      const dialogRef = this.dialog.open(EmailDialogComponent , {
        maxWidth: '100vw',
        maxHeight: '100vh',
        width: '75em',
        height: 'auto',
      });
      let instance = dialogRef.componentInstance;
      instance.adresse = this.adressePrincipale;
      instance.contactInfos = contactInfos;
      dialogRef.afterClosed().subscribe(result => {
      });

    }
  }
  