import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ContactService} from 'src/app/service/contact.service';
import {Telephone} from 'src/app/models/telephone';
import {ContactInfos} from 'src/app/models/contact-infos';
import { NotificationService } from '../../../notifications/notification-service/notification-service';

@Component({
    selector: 'app-infos-dialog-telephone',
    templateUrl: 'dialog-telephone.component.html',
    styleUrls: ['./dialog-telephone.component.scss']
  })
  export class TelephoneDialogComponent implements OnInit {

    @Input() telephone: Telephone;
    @Input() contactInfos: ContactInfos;
    telephoneForm: UntypedFormGroup;
    telephoneMinLength;
    telephoneMaxLength;
    
    constructor(
      protected translateService: TranslateService,
      protected contactService: ContactService,
      protected notificationService: NotificationService,
      private el: ElementRef
      ) {}

      private telephoneFrancaisValidators = [
        Validators.required, 
        Validators.pattern("^[0-9]{10,10}$")
      ];

      private telephoneEtangersValidators = [
        Validators.required, 
        Validators.pattern("^[0-9]{1,20}$")
      ];

      public ngOnInit(): void {

        //init telephoneForm - composant
        this.telephoneForm = new UntypedFormGroup({
          indicatif: new UntypedFormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(4)]),
          numero: new UntypedFormControl('', [Validators.required]),
        });

        this.getNumeroForm().updateValueAndValidity;
        this.getIndicatifForm().updateValueAndValidity;

        this.getIndicatifForm().valueChanges.subscribe(value => {
          if(value == 33) {
            this.getNumeroForm().setValidators(this.telephoneFrancaisValidators);
            this.telephoneMinLength = 10;
            this.telephoneMaxLength = 10;
          } else {
            this.getNumeroForm().setValidators(this.telephoneEtangersValidators);
            this.telephoneMinLength = 1;
            this.telephoneMaxLength = 20;
          }
          this.getNumeroForm().updateValueAndValidity();
        });

        this.getIndicatifForm().setValue(this.telephone.indicatif);
        this.getNumeroForm().setValue(this.telephone.noTelephone);
    }
    // FORMULAIRE

    getIndicatifForm() { return this.telephoneForm.get('indicatif'); }
    getNumeroForm() { return this.telephoneForm.get('numero'); }

    getNumeroMinLength() {
      return this.telephoneMinLength;
    }

    getNumeroMaxLength() {
      return this.telephoneMaxLength;
    }

    public getNumeroErrorMessage() {
      if (this.getNumeroForm().hasError('required')) {
        return this.translateService.instant('libelle.infos.data.contacts.dialog.telephone.erreurs.numero');
      }
      if (this.getNumeroForm().hasError('minlength')) {
        return this.translateService.instant('libelle.infos.data.contacts.dialog.telephone.erreurs.numero');
      }
      if (this.getNumeroForm().hasError('maxlength')) {
        return this.translateService.instant('libelle.infos.data.contacts.dialog.telephone.erreurs.numero');
      }
    }

    public getIndicatifErrorMessage() {
      if (this.getIndicatifForm().hasError('required')) {
        return this.translateService.instant('libelle.infos.data.contacts.dialog.telephone.erreurs.indicatif');
      }
      if (this.getIndicatifForm().hasError('minlength')) {
        return this.translateService.instant('libelle.infos.data.contacts.dialog.telephone.erreurs.indicatif');
      }
      if (this.getIndicatifForm().hasError('maxlength')) {
        return this.translateService.instant('libelle.infos.data.contacts.dialog.telephone.erreurs.indicatif');
      }
    }

    // CONTROLES ET ACTIONS

    public isTelephoneFormValid() {
      let isValid: boolean = true;
      if (this.getIndicatifForm().invalid) {
        isValid = false;
      }
      if (this.getNumeroForm().invalid) {
        isValid = false;
      }
      return isValid;
    }


    public modifierTelephone(){
      this.telephone.indicatif = this.getIndicatifForm().value;
      this.telephone.noTelephone = this.getNumeroForm().value;
      this.contactInfos.setDonneesRenseignees("(+"+ this.telephone.indicatif +") " + this.telephone.noTelephone);

      this.updateTelephone(this.telephone).subscribe(
        (val) => {
          this.notificationService.success(this.translateService.instant('libelle.infos.data.contacts.dialog.telephone.notifications.success'));
        },
        response => {
          this.notificationService.error(this.translateService.instant('libelle.infos.data.contacts.dialog.telephone.notifications.error'));
        });
    }

    public updateTelephone(telephone: Telephone) {
      return this.contactService.updateTelephone(telephone);
    }
      
  }