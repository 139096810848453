import {Injectable} from '@angular/core';
import {DocumentService} from './document.service';
import {MenuInfo} from '../models/menu-info';
import {Observable, of} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {map} from 'rxjs/operators';
import {RouteService} from '../technique/routes/route.service';
import {Categorie} from '../models/categorie';

@Injectable({
  providedIn: 'root'
})
export class ComponentsInfosService {

  private menuInfos: Array<MenuInfo>;

  constructor(private documentService: DocumentService,
              private translateService: TranslateService,
              private routeService: RouteService
  ) {
  }

  public refreshComponentInfos(): Observable<Array<MenuInfo>> {
    if (this.menuInfos) {
      return of(this.menuInfos);
    } else {
      return this.documentService
        .getCategoriesDocument()
        .pipe(map(
          (categories: Array<Categorie>) => {
            this.menuInfos = new Array<MenuInfo>();

            categories.forEach(
              (categorie: Categorie) => {

                let iconName = this.translateService.instant('icon.' + categorie.code);
                iconName = (iconName !== 'icon.' + categorie.code) ? iconName : 'fas fa-file';

                let title = this.translateService.instant('title.' + categorie.code);
                title = title !== 'title.' + categorie.code ? title : categorie.libelle;

                const menuInfo = (categorie.code === 'NOTES') ?
                  new MenuInfo(categorie.code, title, categorie.ordre, 'notes', '', iconName) :
                  new MenuInfo(categorie.code, title, categorie.ordre, 'documents/' + categorie.code, '', iconName);

                this.menuInfos.push(menuInfo);
              });

            this.menuInfos.forEach(
              (menuInfo: MenuInfo) => {
                this.routeService.setStandardCorrespondingRoutes(menuInfo.targetPath);
              });

            return this.menuInfos;
          },
          (err) => {
            console.log(err.message);
          }
        ));
    }
  }
}
