import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {EtudiantService} from '../../../service/etudiant.service';
import {SignoutService} from '../../../service/signout.service';
import {MenuItemInfo} from '../../drop-down-menu/menu-item-info';
import {ComponentsInfosService} from '../../../service/components-infos.service';
import {TranslateService} from '@ngx-translate/core';
import {MainComponent} from '../main-component';
import {RouteService} from '../../../technique/routes/route.service';
import { GuiRoutes } from 'src/app/gui-routes';
import { ItemExtended } from 'ng-cocktail-ui';

@Component({
  selector: 'app-main',
  templateUrl: './main-desktop.component.html',
  styleUrls: ['./main-desktop.component.scss']
})
export class MainDesktopComponent extends MainComponent {

  readonly dropDownMenuItemInfos: Array<MenuItemInfo>;
  isHeaderDropDownMenuHidden: boolean;

  constructor(
    protected router: Router,
    protected etudService: EtudiantService,
    protected signoutService: SignoutService,
    protected menuInfoService: ComponentsInfosService,
    protected translate: TranslateService,
    protected routeService: RouteService) {
    super(router, etudService, signoutService, menuInfoService, translate, routeService);
    this.isHeaderDropDownMenuHidden = true;
    this.dropDownMenuItemInfos = new Array<MenuItemInfo>(this.menuItemInfos, this.menuItemDeconnexion);
  }

  public openItem(menuItemExtended: ItemExtended): void {
    this.router.navigate([menuItemExtended.path]);
  }

  public toggleDropDownMenu(): void {
    this.isHeaderDropDownMenuHidden = !this.isHeaderDropDownMenuHidden;
  }

  public closeDropDownMenu(): void {
    this.isHeaderDropDownMenuHidden = true;
  }

  public dropDownMenuItemClick($event: MenuItemInfo): void {
    switch ($event) {
      case this.menuItemDeconnexion :
        this.signoutService.signOut();
        break;

      case this.menuItemInfos :
        this.toggleDropDownMenu();
        this.router.navigate([GuiRoutes.INFOS]);
        break;

      default: break;
    }
  }

  public home() {
    this.router.navigate(['/']);
  }
  
}
