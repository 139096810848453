import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ReleveDeNotes} from '../models/releve-notes';
import {Observable} from 'rxjs';
import {BackendHttpClient} from '../technique/http-client/backend-http-client.service';

@Injectable({
  providedIn: 'root'
})
export class InscriptionService {

  constructor(private http: BackendHttpClient) {}

  public getRelevesDeNotes(idInscription: number): Observable<Array<ReleveDeNotes>> {
    return this.http.get<Array<ReleveDeNotes>>('sve/inscription/' + idInscription + '/releves-notes');
  }
}
