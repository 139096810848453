import {Injectable} from '@angular/core';
import {AuthentificationTokenService} from '../technique/authentication/authentification-token.service';
import {Document} from '../models/document';
import {FileService} from 'src/app/service/file.service';
import {Observable} from 'rxjs';
import {Categorie} from '../models/categorie';
import {tap} from 'rxjs/operators';
import {BackendHttpClient} from '../technique/http-client/backend-http-client.service';
import {HttpResponse} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(
    private http: BackendHttpClient,
    private authentificationTokenService: AuthentificationTokenService,
    private fileService: FileService) {
  }

  public getDocuments(categorieDocument: string): Observable<Array<Document>> {
    return this.http.get<Array<Document>>(
      'sve/documents',
      {
        params: {
          categorie: categorieDocument,
          idEtudiant: this.authentificationTokenService.getEtudiantId()
        }
      });
  }

  public getDocument(idDocument: string): Observable<HttpResponse<Blob>> {
    return this.fileService.downloadFile('sve/documents/' + idDocument);
  }

  public getCategoriesDocument(): Observable<Array<Categorie>> {
    return this.http.get<Array<Categorie>>('sve/documents/categories')
      .pipe(
        tap(categories => categories.sort((cat1, cat2) => this.compare(cat1.ordre, cat2.ordre)))
      );
  }

  private compare(a: any, b: any): number {
    try {
      return a - b;
    } catch (error) {
      console.warn('Erreur lors de la comparaison : ', error);
      return 0;
    }
  }
}
