import {Injectable} from '@angular/core';
import {DocumentService} from './document.service';
import {saveAs} from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class DocumentTelechargementService {

  constructor(private documentService: DocumentService) { }

  public ouvrirDocument(idDocument: string): void {
    this.documentService.getDocument(idDocument).subscribe(
      (response) => {
        const filetype = response.body.type;
        const headers = response.headers.get('content-disposition').split('=');
        const filename = headers[1];
        const newBlob = new Blob([response.body], {type: filetype});
        try {
          saveAs(newBlob, filename);
        } catch (error) {
          alert('L\'ouverture du document a été interrrompu par le navigateur. (Cause potentielle : Bloqueur de publicité');
        }
      },
      (err) => {
        console.log(err.message);
      }
    );
  }
}
