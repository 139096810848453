import {Injectable} from '@angular/core';
import {Router, RouterStateSnapshot} from '@angular/router';
import {ScreenService} from '../../../service/screen.service';
import {RouteService} from '../../routes/route.service';
import {UrlGuard} from '../url/url.guard';
import {ComponentsInfosService} from '../../../service/components-infos.service';

@Injectable({
  providedIn: 'root'
})
export class MobileGuard extends UrlGuard {

  constructor(
    protected router: Router,
    protected screenService: ScreenService,
    protected routeService: RouteService,
    protected componentsInfosService: ComponentsInfosService) {
    super(componentsInfosService);
  }

  protected guardAction(state: RouterStateSnapshot): boolean {
    const isDesktop = this.screenService.isDesktop();
    if (isDesktop) {
      this.router.navigate(
        this.routeService.desktopRouteOrDefault(state.url.substring(1))
      );
    }
    return !isDesktop;
  }

}
