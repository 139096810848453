import {Injectable} from '@angular/core';
import {FrontError} from './front-error';

@Injectable({
  providedIn: 'root'
})
export class FrontErrorService {

  private lastError: FrontError;

  public handleError(lastError: FrontError): void {
    this.lastError = lastError;
    this.lastError.process();
  }

  public getLastError(): FrontError {
    return this.lastError;
  }

}
