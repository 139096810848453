import { Injectable } from '@angular/core';
import {AuthentificationService} from '../technique/authentication/authentification.service';

@Injectable({
  providedIn: 'root'
})
export class SignoutService {

  private confirmSignOut = true;

  constructor(private authService: AuthentificationService) { }

  public setConfirmSignOut(confirmSignOut: boolean): SignoutService {
    this.confirmSignOut = confirmSignOut;
    return this;
  }

  public getConfirmSignOut(): boolean {
    return this.confirmSignOut;
  }

  public enableConfirmSignOut(): SignoutService {
    this.confirmSignOut = true;
    return this;
  }

  public disableConfirmSignOut(): SignoutService {
    this.confirmSignOut = false;
    return this;
  }

  public isConfirmSignOutDisabled(): boolean {
    return this.confirmSignOut === false;
  }

  public isConfirmSignOutEnabled(): boolean {
    return this.confirmSignOut === true;
  }

  public confirmSignOutAction(): boolean {
    return confirm('Êtes-vous certain de vouloir vous déconnecter ?');
  }

  public signOut(): void {
    if (this.isConfirmSignOutDisabled() || this.confirmSignOutAction()) {
      this.authService.signOut();
    }
  }

}
