import {TranslateLoader} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {TranslationService} from './translation.service';
import {map} from 'rxjs/operators';
import {TranslateResponse} from './translate-response';

export class WebpackTranslateLoader implements TranslateLoader {
  constructor(private translationService: TranslationService) {}

  public getTranslation(lang: string): Observable<any> {
    return this.translationService.getTranslation(lang).pipe(
      map((translateResponse: TranslateResponse) => {
        return translateResponse.translation;
      })
    );
  }
}
