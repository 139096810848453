import {Injectable} from '@angular/core';
import {AuthentificationTokenService} from '../technique/authentication/authentification-token.service';
import {Document} from '../models/document';
import {FileService} from 'src/app/service/file.service';
import {Observable} from 'rxjs';
import {Contact} from '../models/contact';
import {tap} from 'rxjs/operators';
import {BackendHttpClient} from '../technique/http-client/backend-http-client.service';
import {HttpHeaders, HttpResponse} from '@angular/common/http';
import { Pays } from '../models/pays';
import { Commune } from '../models/commune';
import { Adresse } from '../models/adresse';
import { ParametreContact } from '../models/parametre-contact';
import { Telephone } from '../models/telephone';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(
    private http: BackendHttpClient,
    private authentificationTokenService: AuthentificationTokenService) {
  }

  public getContact(): Observable<Contact> {
    return this.http.get<Contact>(
      'sve/contact',
      {
        params: {
          id: this.authentificationTokenService.getPersId()
        }
      });
  }

  public getPays(): Observable<Array<Pays>> {
    return this.http.get<Array<Pays>>(
      'sve/contact/adresses/pays',{});
  }

  public searchCommunes(codePostal: string): Observable<Array<Commune>> {
    return this.http.get<Array<Commune>>(
      'sve/contact/adresses/communes',
      {
        params: {
          codePostal: codePostal
        }
      });
  }

  public updateAdresse(adresse: Adresse){
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    return this.http.patch(
      'sve/contact/adresse',adresse,{headers: headers});
  }

  public getParametresContact() {
    return this.http.get<ParametreContact>(
      'sve/contact/parametres',{});
  }

  public updateTelephone(telephone : Telephone) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    return this.http.patch(
      'sve/contact/telephone',telephone,{headers: headers});
  }

  public updateEmail(adresse: Adresse){
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    return this.http.patch(
      'sve/contact/email',adresse,{headers: headers});
  }

}