import {FrontErrorMessages} from '../front-error-messages';
import {FrontErrorRedirection} from '../front-error-redirection';
import {GuiRoutes} from '../../../gui-routes';

export class FrontErrorTokenExpired extends FrontErrorRedirection {

  constructor() {
    super();
    this.setRedirectionRoute( [GuiRoutes.RECONNEXION] )
      .setNotificationsEnabled(false)
      .setInternalErrorMessages(
        new FrontErrorMessages()
          .setTitle('Expiration du token utilisateur')
          .setMessage('Une requête vers le back a été tentée avec un token utilisateur expiré')
          .setHints('Une erreur a été renvoyé pour pouvoir tenter une reconnexion sécurisée')
      ).setUserErrorMessages(
        new FrontErrorMessages()
          .setTitle('Session expirée')
          .setMessage('Pour des raisons de sécurité votre session a expiré.')
          .setHints('Cette erreur se produit généralement quand une session est restée connectée durant un certain temps. Veuillez renouveler votre connexion.')
    );
  }

  public logAction(): void {
    console.warn(this);
  }

  public notifyAction(): void {
    const userErrorMessages: FrontErrorMessages = this.getUserErrorMessages();
    this.getNotificationService().notice(userErrorMessages.getMessage(), userErrorMessages.getTitle());
  }
}
