import {Inscription} from '../../models/inscription';
import {ReleveDeNotes} from '../../models/releve-notes';
import {EtudiantService} from '../../service/etudiant.service';
import {DocumentService} from '../../service/document.service';
import {DocumentTelechargementService} from '../../service/document-telechargement.service';
import {InscriptionService} from '../../service/inscription.service';
import {catchError, tap} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';

export class NotesComponent {

  inscriptions: Array<Inscription>;
  relevesDeNotes: Array<ReleveDeNotes>;
  idInscriptionSelectionne: number;

  constructor(
    protected etudService: EtudiantService,
    protected documentService: DocumentService,
    protected documentTelechargementService: DocumentTelechargementService,
    protected inscriptionService: InscriptionService) {
    this.inscriptions = new Array<Inscription>();
    this.relevesDeNotes = new Array<ReleveDeNotes>();
  }

  public getInscriptions(): Observable<Array<Inscription>> {
    return this.etudService.getInscriptions().pipe(
      tap(
        (inscriptions: Array<Inscription>) => {
          this.inscriptions = inscriptions;
        }
      ),
      catchError(
        (err) => {
          this.inscriptions = new Array<Inscription>();
          return throwError(new Error(err.error.message));
        }
      )
    );
  }

  public selectInscription(idInscription: number): void {
    this.getRelevesDeNotesByIdInscription(idInscription)
      .pipe(
        tap(() => {
          this.idInscriptionSelectionne = idInscription;
        }),
      ).subscribe();
  }

  public getRelevesDeNotesByIdInscription(idInscription: number): Observable<Array<ReleveDeNotes>> {
    return this.inscriptionService
      .getRelevesDeNotes(idInscription)
      .pipe(
        tap((relevesDeNotes: Array<ReleveDeNotes>) => {
          this.relevesDeNotes = relevesDeNotes;
        }),
        catchError(
          (err) => {
            this.relevesDeNotes = new Array<ReleveDeNotes>();
            return throwError(new Error(err.error.message));
          }
        )
      );
  }

  public ouvrirReleveDeNotes(idDocument: string): void {
    this.documentService.getDocument(idDocument).subscribe(
      () => {
        this.documentTelechargementService.ouvrirDocument(idDocument);
      },
      (err) => {
        return throwError(new Error(err.error.message));
      }
    );
  }

}
