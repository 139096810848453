import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BackendHttpClient} from '../technique/http-client/backend-http-client.service';

@Injectable({providedIn: 'root'})
export class FileService {

  constructor(private http: BackendHttpClient) {}

  public downloadFile(url: string): Observable<HttpResponse<Blob>> {
    return this.http.get<HttpResponse<Blob>>(url, { observe: 'response', responseType: 'blob' });
  }

}
