import {Injectable} from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {ScreenState} from './screen-state';

@Injectable({
  providedIn: 'root'
})
export class ScreenService {

  private lastScreenState: boolean;

  constructor(private deviceService: DeviceDetectorService) {
    this.lastScreenState = this.isDesktop();
  }

  public refreshScreenState(): ScreenState {
    const newScreenState = this.isDesktop();
    const stateChanged = newScreenState !== this.lastScreenState;
    if (stateChanged) {
      this.lastScreenState = newScreenState;
    }
    return new ScreenState().setDesktop(newScreenState).setUpdated(stateChanged);
  }

  public isScreenWidthSufficient(): boolean {
    return window.matchMedia('(min-width: 1200px)').matches;
  }

  public isDesktop(): boolean {
    return this.isScreenWidthSufficient() && this.deviceService.isDesktop(window.navigator.userAgent);
  }
}
