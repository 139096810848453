export class ScreenState {

  private updated: boolean;
  private desktop: boolean;

  public isUpdated(): boolean {
    return this.updated;
  }

  public setUpdated(updated: boolean): ScreenState {
    this.updated = updated;
    return this;
  }

  public isDesktop(): boolean {
    return this.desktop;
  }

  public setDesktop(desktop: boolean): ScreenState {
    this.desktop = desktop;
    return this;
  }

}
