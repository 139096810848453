import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ContactService} from 'src/app/service/contact.service';
import {Adresse} from 'src/app/models/adresse';
import {ContactInfos} from 'src/app/models/contact-infos';
import {NotificationService} from '../../../notifications/notification-service/notification-service';

@Component({
    selector: 'app-infos-dialog-email',
    templateUrl: 'dialog-email.component.html',
    styleUrls: ['./dialog-email.component.scss']
  })
  export class EmailDialogComponent implements OnInit {

    @Input() adresse: Adresse;
    @Input() contactInfos: ContactInfos;
    emailForm: UntypedFormGroup;
    
    
    constructor(
      protected translateService: TranslateService,
      protected contactService: ContactService,
      protected notificationService: NotificationService,
      private el: ElementRef
      ) {}

      public ngOnInit(): void {

        //init emailForm - composant
        this.emailForm = new UntypedFormGroup({
          email: new UntypedFormControl('', [
            Validators.required, 
            Validators.maxLength(100),
            Validators.pattern("^[a-zA-Z0-9._-]+@[a-z0-9.-]+\\.[a-z]{2,}$")
          ]),
        });

        this.getEmailForm().setValue(this.adresse.email);
    }


    // FORMULAIRE

    getEmailForm() { return this.emailForm.get('email'); }

    public getEmailErrorMessage() {
      if (this.getEmailForm().hasError('required')) {
        return this.translateService.instant('libelle.infos.data.contacts.dialog.email.erreurs.email');
      }
      if (this.getEmailForm().hasError('maxlength')) {
        return this.translateService.instant('libelle.infos.data.contacts.dialog.email.erreurs.email');
      }
    }

    // CONTROLES ET ACTIONS

    public isEmailFormValid() {
      let isValid: boolean = true;
      if (this.getEmailForm().invalid) {
        isValid = false;
      }
      return isValid;
    }


    public modifierEmail(){
      this.adresse.email = this.getEmailForm().value;
      this.contactInfos.setDonneesRenseignees(this.adresse.email);

      this.updateEmail(this.adresse).subscribe(
      (val) => {
        this.notificationService.success(this.translateService.instant('libelle.infos.data.contacts.dialog.email.notifications.success'));
      },
      response => {
        this.notificationService.error(this.translateService.instant('libelle.infos.data.contacts.dialog.email.notifications.error'));
      });
    }

    public updateEmail(adresse: Adresse) {
      return this.contactService.updateEmail(adresse);
    }
      
  }