import {HttpHeaders} from '@angular/common/http';
import {HttpHeadersTypes} from './http-headers-types.enum';
import {HttpHeadersValues} from './http-headers-values.enum';

export class UrlEncodedContentHttpHeaders extends HttpHeaders {
  constructor() {
    super();
    this.set(HttpHeadersTypes.CONTENT_TYPE, HttpHeadersValues.URL_ENCODED_CONTENT_TYPE);
  }
}
