import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MenuItemInfo} from '../drop-down-menu/menu-item-info';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-drop-down-menu-item',
  templateUrl: './drop-down-menu-item.component.html',
  styleUrls: ['./drop-down-menu-item.component.scss']
})
export class DropDownMenuItemComponent implements OnInit {

  @Input() itemDescription: MenuItemInfo;
  @Output() itemClick: EventEmitter<MenuItemInfo>;
  icon: string;
  labelKey: string;

  constructor(protected translate: TranslateService) {
    this.itemClick = new EventEmitter<MenuItemInfo>();
  }

  public clickEvent(): void {
    this.itemClick.emit(this.itemDescription);
  }

  public ngOnInit(): void {
    this.icon = this.itemDescription.getIcon();
    this.labelKey = this.itemDescription.getLabelKey();
  }

}
