import {Observable, throwError as observableThrowError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpRequest} from '@angular/common/http';
import {Router} from '@angular/router';
import {AuthentificationService} from '../authentication/authentification.service';
import {AuthentificationTokenService} from '../authentication/authentification-token.service';
import {FrontErrorService} from '../error/front-error.service';
import {FrontErrorRequest} from '../error/errors/front-error-request';
import {FrontErrorTokenExpired} from '../error/errors/front-error-token-expired';
import {FrontErrorUnauthorized} from '../error/errors/front-error-unauthorized';
import {FrontErrorFromBack} from '../error/errors/front-error-from-back';
import {FrontErrorNoResponse} from '../error/errors/front-error-no-response';

@Injectable({
  providedIn: 'root'
})
export class ErrorRequestInterceptor {

  constructor(private router: Router,
              private authentificationTokenService: AuthentificationTokenService,
              private authentificationService: AuthentificationService,
              private frontErrorService: FrontErrorService,
  ) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(
        errorReponse => {
          switch (errorReponse.status) {
            case 0:
              this.frontErrorService.handleError(new FrontErrorNoResponse());
              break;
            case 401:
              this.authentificationService.cleanAllSessionTokens();
              this.frontErrorService.handleError(new FrontErrorTokenExpired());
              break;
            case 403:
              this.authentificationService.cleanAllSessionTokens();
              this.frontErrorService.handleError(new FrontErrorUnauthorized());
              break;
            case 404:
              this.frontErrorService.handleError(new FrontErrorRequest());
              break;
            case 500:
              this.authentificationService.cleanAllSessionTokens();
              this.frontErrorService.handleError(new FrontErrorFromBack(errorReponse.error.message));
              break;
          }
          return observableThrowError(errorReponse);
        }));

  }
}
