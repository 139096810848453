import {Component} from '@angular/core';
import {MainComponent} from '../main-component';
import {Router} from '@angular/router';
import {EtudiantService} from '../../../service/etudiant.service';
import {SignoutService} from '../../../service/signout.service';
import {ComponentsInfosService} from '../../../service/components-infos.service';
import {TranslateService} from '@ngx-translate/core';
import {MenuItemInfo} from '../../drop-down-menu/menu-item-info';
import {RouteService} from '../../../technique/routes/route.service';
import {UrlConverter} from '../../../technique/url/url-converter';
import { GuiRoutes } from 'src/app/gui-routes';

@Component({
  selector: 'app-main-mobile',
  templateUrl: './main-mobile.component.html',
  styleUrls: ['./main-mobile.component.scss']
})
export class MainMobileComponent extends MainComponent {

  readonly mobileMenuItems: Array<MenuItemInfo>;
  isMainMenuVisible: boolean;
  iconKey: string;
  titleKey: string;

  constructor(
    protected router: Router,
    protected etudService: EtudiantService,
    protected signoutService: SignoutService,
    protected menuInfoService: ComponentsInfosService,
    protected translate: TranslateService,
    protected routeService: RouteService
  ) {
    super(router, etudService, signoutService, menuInfoService, translate, routeService);
    this.mobileMenuItems = new Array<MenuItemInfo>(this.menuItemInfos, this.menuItemDeconnexion, this.menuItemRetour);
    this.initAccueil();
  }

  public setIconKey(iconKey: string): MainMobileComponent {
    this.iconKey = iconKey;
    return  this;
  }

  public setTitleKey(titleKey: string): MainMobileComponent {
    this.titleKey = titleKey;
    return this;
  }

  public toggleMobileMenu(): void {
    this.isMainMenuVisible = !this.isMainMenuVisible;
  }

  public closeMobileMenu(): void {
    this.isMainMenuVisible = false;
  }

  public initAccueil(): void {
    this.closeMobileMenu();
    this.setIconKey('icon.ACCUEIL').setTitleKey('title.ACCUEIL');
  }

  public mainMenuItemClick($event: MenuItemInfo): void {
    switch ($event) {
      case this.menuItemInfos :
        this.closeMobileMenu();
        this.setIconKey('icon.MES_INFOS').setTitleKey('title.MES_INFOS');
        this.router.navigate(UrlConverter.desktopUrlToMobileRoute(GuiRoutes.INFOS));
        break;

      case this.menuItemDeconnexion :
        this.signoutService.signOut();
        break;

      case this.menuItemRetour :
        this.toggleMobileMenu();
        break;

      default: break;
    }
  }

}
