import {Component, OnInit} from '@angular/core';
import {NotesComponent} from '../notes-component';
import {Inscription} from '../../../models/inscription';
import {EtudiantService} from '../../../service/etudiant.service';
import {DocumentService} from '../../../service/document.service';
import {DocumentTelechargementService} from '../../../service/document-telechargement.service';
import {InscriptionService} from '../../../service/inscription.service';
import {MainMobileComponent} from '../../main/mobile/main-mobile.component';
import {ReleveDeNotes} from '../../../models/releve-notes';

@Component({
  selector: 'app-notes-mobile',
  templateUrl: './notes-mobile.component.html',
  styleUrls: ['./notes-mobile.component.scss']
})
export class NotesMobileComponent extends NotesComponent {

  constructor(
    protected etudService: EtudiantService,
    protected documentService: DocumentService,
    protected documentTelechargementService: DocumentTelechargementService,
    protected inscriptionService: InscriptionService,
    protected mainMobileComponent: MainMobileComponent
  ) {
    super(etudService, documentService, documentTelechargementService, inscriptionService);
  }

  public ngOnInit(): void {
    this.getInscriptions().subscribe();
    this.mainMobileComponent.setIconKey('icon.NOTES').setTitleKey('title.NOTES');
  }

  public classBoutonFormation(idInscription: number): string {
    if (idInscription === this.idInscriptionSelectionne) {
      return 'formation_unit_button formation_unit_button-selected';
    } else {
      return 'formation_unit_button';
    }
  }

  public retourListeInscription(): void {
    this.idInscriptionSelectionne = null;
    this.relevesDeNotes = new Array<ReleveDeNotes>();
  }

  public libelleCollonneDiplome(inscription: Inscription): string {
    if (inscription.parcoursDiplome) {
      return inscription.diplome
        .concat(' - ')
        .concat(inscription.parcoursDiplome);
    }
    return inscription.diplome;
  }

  public classLigneFormation(idInscription: number): string {
    if (idInscription === this.idInscriptionSelectionne) {
      return 'formation_unit formation_unit_selected';
    } else if (this.idInscriptionSelectionne) {
      return  'formation_unit formation_unit_not_selected';
    } else {
      return 'formation_unit';
    }
  }

}
