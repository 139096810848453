import {Component, OnInit} from '@angular/core';
import {EtudiantService} from '../../../service/etudiant.service';
import {DocumentService} from '../../../service/document.service';
import {DocumentTelechargementService} from '../../../service/document-telechargement.service';
import {InscriptionService} from 'src/app/service/inscription.service';
import {flatMap} from 'rxjs/operators';
import {NotesComponent} from '../notes-component';

@Component({
  selector: 'app-notes',
  templateUrl: './notes-desktop.component.html',
  styleUrls: ['./notes-desktop.component.scss']
})
export class NotesDesktopComponent extends NotesComponent implements OnInit {

  constructor(
    etudService: EtudiantService,
    documentService: DocumentService,
    documentTelechargementService: DocumentTelechargementService,
    inscriptionService: InscriptionService
  ) {
    super(etudService, documentService, documentTelechargementService, inscriptionService);
  }

  public ngOnInit(): void {
    this.getInscriptions().pipe(
      flatMap( () => {
        return this.getRelevesDeNotesByIdInscription(this.inscriptions[0].id);
      })
    ).subscribe();
  }

  public classLigneFormation(idInscription: number): string {
    if (idInscription === this.idInscriptionSelectionne) {
      return 'formation_unit formation_unit_selected';
    } else {
      return 'formation_unit';
    }
  }

}
