import {FrontErrorNotify} from './front-error-notify';

export class FrontErrorRedirection extends FrontErrorNotify {

  private redirectionsEnabled: boolean;
  private redirectionRoute: Array<string>;

  constructor() {
    super();
    this.redirectionsEnabled = true;
  }

  public setRedirectionsEnabled(redirectionsEnabled: boolean): FrontErrorRedirection {
    this.redirectionsEnabled = redirectionsEnabled;
    return this;
  }

  public isRedirectionsEnabled(): boolean {
    return this.redirectionsEnabled;
  }

  public setRedirectionRoute(redirectionRoute: Array<string>): FrontErrorRedirection {
    this.redirectionRoute = redirectionRoute;
    return this;
  }

  public getRedirectionRoute(): Array<string> {
    return this.redirectionRoute;
  }

  public redirect(): void {
    if (this.redirectionsEnabled) {
      this.redirectAction();
    }
  }

  public redirectAction(): void {
    this.getRouter().navigate(this.redirectionRoute);
  }

  public process(): void {
    super.process();
    this.redirect();
  }

}
