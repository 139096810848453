import {Component} from '@angular/core';
import {GuiRoutes} from '../../gui-routes';
import {ErrorButtonDescription} from '../erreur/error-button-description';
import {Router} from '@angular/router';
import {ErreurBase} from '../erreur/erreur-base';

@Component({
  selector: 'app-reconnexion',
  templateUrl: './reconnexion.component.html',
  styleUrls: ['./reconnexion.component.scss']
})
export class ReconnexionComponent extends ErreurBase {

  buttonReLogin: ErrorButtonDescription;

  constructor(private router: Router) {
    super();
    this.buttonReLogin = new ErrorButtonDescription().setKey('error.button.reconnexion.reconnexion');
    this.buttonErrorDescriptionList.push(this.buttonReLogin);
  }

  public resolveError($event: ErrorButtonDescription): void {
    if ($event === this.buttonReLogin) {
      this.router.navigate([GuiRoutes.ACCUEIL]);
    }
  }

}
