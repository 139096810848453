import {Component, HostListener, Injector, OnInit} from '@angular/core';
import {InjectableLocatorService} from './technique/locator/injectable-locator.service';
import {ScreenService} from './service/screen.service';
import {Router} from '@angular/router';
import {RouteService} from './technique/routes/route.service';
import {TranslateService} from '@ngx-translate/core';
import { GuiRoutes } from './gui-routes';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  private resizeEndTimeOut: any;

  constructor(
    private injector: Injector,
    private screenService: ScreenService,
    private router: Router,
    private routeService: RouteService,
    private translateService: TranslateService
  ) {
    InjectableLocatorService.injector = injector;
    translateService.setDefaultLang('fr');
    translateService.use('fr');
  }

  public ngOnInit(): void {
    this.routeService.setStandardCorrespondingRoutes(GuiRoutes.INFOS);
  }

  private resizeEndEvent(): void {
    const screenState = this.screenService.refreshScreenState();
    if (screenState.isUpdated()) {
      if (screenState.isDesktop()) {
        this.router.navigate(this.routeService.desktopRouteOrDefault(this.router.url.substring(1)));
      } else {
        this.router.navigate(this.routeService.mobileRouteOrDefault(this.router.url.substring(1)));
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  private handleResizeEvent($event) {
    clearTimeout(this.resizeEndTimeOut);
    this.resizeEndTimeOut = setTimeout(this.resizeEndEvent.bind(this), 500);
  }

}
