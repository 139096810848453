import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CommonModule, DatePipe} from '@angular/common';
import {AuthentificationTokenService} from './technique/authentication/authentification-token.service';
import {AuthenticationRequestInterceptor} from './technique/http-interceptor/authentication-interceptor.service';
import {ErrorRequestInterceptor} from './technique/http-interceptor/error-interceptor.service';
import {AuthentificationService} from './technique/authentication/authentification.service';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {TransformLongsLibellesPipe} from './service/transform-longs-libelles.pipe';

@NgModule({
    declarations: [
        TransformLongsLibellesPipe
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        HttpClientModule
    ],
    exports: [
        TransformLongsLibellesPipe
    ],
    providers: [
        TransformLongsLibellesPipe,
        DatePipe,
        AuthentificationTokenService,
        AuthentificationService,
        [
            { provide: HTTP_INTERCEPTORS, useClass: AuthenticationRequestInterceptor, multi: true },
            { provide: HTTP_INTERCEPTORS, useClass: ErrorRequestInterceptor, multi: true }
        ]
    ]
})

export class AppCommonModule {}
