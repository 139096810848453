import {Component, Directive, Injectable, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {DocumentService} from '../../service/document.service';
import {DocumentTelechargementService} from '../../service/document-telechargement.service';
import {catchError} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {Document} from '../../models/document';
import {EtudiantService} from '../../service/etudiant.service';
import {Inscription} from '../../models/inscription';
import {DocumentInscriptionInfos} from '../../models/document-inscription-infos';
import {TranslationService} from '../../technique/translation/translation.service';
import {LanguageNameResponse} from '../../technique/translation/language-name-response';
import {TranslateService} from '@ngx-translate/core';

@Directive({ selector : '[appDocumentComponent]' })
export abstract class DocumentDirective implements OnInit {

  constructor(protected activatedRoute: ActivatedRoute,
              protected documentService: DocumentService,
              protected documentTelechargementService: DocumentTelechargementService,
              protected translationService: TranslationService,
              protected translate: TranslateService,
              protected etudiantService: EtudiantService
  ) {
  }

  documents: Array<DocumentInscriptionInfos>;
  languages: Map<string, string>;
  categorie: string;
  iconKey: string;
  titleKey: string;
  aucunDocumentMessageKey: string;
  hasDiplome: boolean;
  isLocalized: boolean;

  private static hasDiplome(map: Map<Inscription, Array<Document>>): boolean {
    const inscriptions: Iterator<Inscription> = map.keys();
    let keyIt = inscriptions.next();
    let hasNotFoundDiplome = true;
    while (!keyIt.done && hasNotFoundDiplome) {
      hasNotFoundDiplome = (keyIt.value == null);
      keyIt = inscriptions.next();
    }
    return !hasNotFoundDiplome;
  }

  private static createMapInscriptionDocument(inscriptions: Array<Inscription>, docs: Array<Document>): Map<Inscription, Array<Document>> {
    const map: Map<Inscription, Array<Document>> = new Map();
    inscriptions.push(null);
    for (const inscription of inscriptions) {
      const idInscription = inscription ? inscription.id : null;
      const matchingDocs: Array<Document> = docs.filter(d => d.idInscription === idInscription);
      if (matchingDocs.length > 0) { map.set(inscription, matchingDocs); }
    }
    return map;
  }

  private static createInscriptionDocumentInfo(map: Map<Inscription, Array<Document>>):
    Array<DocumentInscriptionInfos> {
    const documentsInfos: Array<DocumentInscriptionInfos> = new Array();
    const entries: Iterator <[Inscription, Array<Document>]> = map.entries();
    let entryIt = entries.next();
    while (!entryIt.done) {
      const entry = entryIt.value;
      const inscription: Inscription = entry[0];
      const documents: Array<Document> = entry[1];
      documentsInfos.push(...documents.map(d => new DocumentInscriptionInfos().setDocumentInfos(d).setInscriptionInfos(inscription)));
      entryIt = entries.next();
    }
    return documentsInfos;
  }

  public getLanguageNameForCodeDocInfo(docInfo: DocumentInscriptionInfos): string {
    const lang: string = docInfo?.getDocumentInfos()?.codeLangue;
    return lang ? this.languages.get(lang) : this.translate.instant('org.cocktail.ged.codelangue.absent');
  }

  public getLanguageNameForCode(langCode: string): string {
    return this.languages.get(langCode);
  }

  public ngOnInit(): void {
    this.activatedRoute.params.subscribe(
      (routeParams: Params) => {
        this.languages = new Map<string, string>();
        this.isLocalized = false;
        this.categorie = routeParams.categorie;
        this.iconKey = 'icon.' + this.categorie;
        this.titleKey = 'title.' + this.categorie;
        this.aucunDocumentMessageKey = 'message.aucun.document.' + this.categorie;
        this.getDocuments().subscribe(
          (docs: Array<Document>) => {
            this.etudiantService.getInscriptions().subscribe(
              (inscriptions: Array<Inscription>) => {
                this.documents = new Array<DocumentInscriptionInfos>();
                const map: Map<Inscription, Array<Document>> = DocumentDirective.createMapInscriptionDocument(inscriptions, docs);
                this.hasDiplome = DocumentDirective.hasDiplome(map);
                this.documents = DocumentDirective.createInscriptionDocumentInfo(map);
                this.createMapLanguages(this.documents);
              }
            );
          }
        );
      });
  }

  private createMapLanguages(documents: Array<DocumentInscriptionInfos>): void {
    const uniqueLanguageCodes: Set<string> = new Set(documents.map(doc => doc.getDocumentInfos().codeLangue).filter(code => code));
    uniqueLanguageCodes.forEach(code => {
      this.translationService.getLanguageNameForCode(code, navigator.language).subscribe( (languageNameResponse: LanguageNameResponse) => {
        this.languages.set(code, languageNameResponse.languageName);
        this.isLocalized = true;
      });
    });
  }

  public getDocuments(): Observable<Array<Document>> {
    return this.documentService
      .getDocuments(this.categorie)
      .pipe(catchError(err => throwError(new Error(err.error.message))));
  }

  public ouvrirDocument(idDocument: string): void {
    this.documentService.getDocument(idDocument).subscribe(
      () => {
        this.documentTelechargementService.ouvrirDocument(idDocument);
      },
      (err) => {
        console.log(err.message);
      }
    );
  }

  public nomDiplome(document: DocumentInscriptionInfos): string {
    const nomDiplome: string = document?.getInscriptionInfos()?.diplome;
    return nomDiplome ? nomDiplome : this.translate.instant('org.cocktail.ged.diplome.absent');
  }

}
