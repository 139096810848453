import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MenuItemInfo} from './menu-item-info';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-drop-down-menu',
  templateUrl: './drop-down-menu.component.html',
  styleUrls: ['./drop-down-menu.component.scss']
})
export class DropDownMenuComponent {

  @Input() items: Array<MenuItemInfo>;
  @Output() itemClick: EventEmitter<MenuItemInfo>;

  constructor(protected translate: TranslateService) {
    this.itemClick = new EventEmitter<MenuItemInfo>();
  }

  public itemClickEvent($event: MenuItemInfo): void {
    this.itemClick.emit($event);
  }
}
