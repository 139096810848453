import {Observable} from 'rxjs';
import {RestRoutes} from '../rest/rest-routes';
import {StorageConstants} from '../storage/storage-constants';
import {Injectable} from '@angular/core';
import {CasTicketValidationResponse} from '../../models/cas-ticket-validation-response';
import {AppHttpHeaders} from '../rest/app-http-headers.enum';
import {BackendHttpClient} from '../http-client/backend-http-client.service';
import {AppUrlService} from '../url/app-url.service';
import {CasUrlResponse} from '../../models/cas-url-response';

@Injectable({
  providedIn: 'root'
})
export class AuthentificationService {

    constructor(private http: BackendHttpClient, private appUrlService: AppUrlService) {}

    public getLogoutCasUrl(): Observable<CasUrlResponse> {
      return this.http.get<CasUrlResponse>(RestRoutes.LOGOUT_CAS_URL);
    }

    public signOut(): void {
        this.cleanAllSessionTokens();
        this.openCasUrlAfterResponse(this.getLogoutCasUrl());
    }

    public getLoginCasUrl(): Observable<CasUrlResponse> {
        return this.http.post<CasUrlResponse>(
          RestRoutes.LOGIN_CAS_URL,
          null,
          {
            headers: AppHttpHeaders.URL_ENCODED_CONTENT,
            params: { serviceUrl: this.appUrlService.getAppUrl() }
          }
        );
    }

    public validateCASTicket(casTicket: string): Observable<CasTicketValidationResponse> {
      return this.http.post<CasTicketValidationResponse>(
        RestRoutes.VALIDATE_TICKET,
        null,
        {
          headers: AppHttpHeaders.URL_ENCODED_CONTENT,
          params: {
            ticket: casTicket,
            serviceUrl: this.appUrlService.getAppUrl()
          }
        }
      );
    }

    public cleanAllSessionTokens(): void {
      sessionStorage.removeItem(StorageConstants.TOKEN_NAME_CAS);
      sessionStorage.removeItem(StorageConstants.TOKEN_NAME_PN);
    }

    public openCasUrlAfterResponse(casUrlProvider: Observable<CasUrlResponse>): void {
      this.cleanAllSessionTokens();
      casUrlProvider.subscribe(
        (casUrlResponse: CasUrlResponse) => {
        window.location.replace(casUrlResponse.url);
      });
    }

    public initCASAuthentification(): void {
      this.openCasUrlAfterResponse(this.getLoginCasUrl());
    }
}
