import {FrontErrorRedirection} from '../front-error-redirection';
import {GuiRoutes} from '../../../gui-routes';
import {FrontErrorMessages} from '../front-error-messages';

export class FrontErrorUnauthorized extends FrontErrorRedirection {

  constructor() {
    super();
    this.setRedirectionRoute([GuiRoutes.ERREUR])
      .setNotificationsEnabled(false)
      .setInternalErrorMessages(
        new FrontErrorMessages()
          .setTitle('Requête non autorisée et/ou avec token invalide')
          .setMessage('Tentative d\'effectuer une requête avec un token invalide ou absent')
          .setHints('La requête a été effectué avec l\'initialisation des tokens'))
      .setUserErrorMessages(
        new FrontErrorMessages()
          .setTitle('Authentification invalide')
          .setMessage('Un problème d\'authenfication a empêché la requête d\'aboutir.')
          .setHints('Essayez de vous reconnecter pour résoudre le problème ou contactez l\'administrateur du site pour obtenir de l\'aide')
      );
  }
}
