import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FrontErrorService} from '../../technique/error/front-error.service';
import {FrontError} from '../../technique/error/front-error';
import {FrontErrorMessages} from '../../technique/error/front-error-messages';
import {ErrorButtonDescription} from './error-button-description';
import {ErreurBase} from './erreur-base';

@Component({
  selector: 'app-erreur',
  templateUrl: './erreur.component.html',
  styleUrls: ['./erreur.component.scss']
})
export class ErreurComponent extends ErreurBase implements OnInit {

  lastError: FrontError;
  userErrorTitle: string;
  userErrorMessage: string;
  userErrorHints: string;
  @Input() bgImageUrl: string;
  @Input() buttonErrorDescriptionList: Array<ErrorButtonDescription>;
  @Input() defaultErrorKey: string;
  @Output() errorResolved: EventEmitter<ErrorButtonDescription>;

  constructor(private errorService: FrontErrorService) {
    super();
    this.errorResolved = new EventEmitter<ErrorButtonDescription>();
  }

  public ngOnInit(): void {
    this.lastError = this.errorService.getLastError();
    if (this.lastError) {
      const userErrorMessages: FrontErrorMessages = this.lastError.getUserErrorMessages();
      this.userErrorTitle = userErrorMessages.getTitle();
      if (!this.userErrorTitle) {
        this.userErrorTitle = userErrorMessages.getMessage();
      } else {
        this.userErrorMessage = userErrorMessages.getMessage();
      }
      this.userErrorHints = userErrorMessages.getHints();
    }
  }

  public dispatchClickEvent(errorButtonDescription: ErrorButtonDescription): void {
    this.errorResolved.emit(errorButtonDescription);
  }
}
