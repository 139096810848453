import {Component} from '@angular/core';
import {DocumentDirective} from '../document.directive';
import {ActivatedRoute} from '@angular/router';
import {DocumentService} from '../../../service/document.service';
import {DocumentTelechargementService} from '../../../service/document-telechargement.service';
import {MainMobileComponent} from '../../main/mobile/main-mobile.component';
import {EtudiantService} from '../../../service/etudiant.service';
import {TranslationService} from '../../../technique/translation/translation.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-document-mobile',
  templateUrl: './documents-mobile.component.html',
  styleUrls: ['./documents-mobile.component.scss']
})
export class DocumentsMobileComponent extends DocumentDirective {

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected documentService: DocumentService,
    protected documentTelechargementService: DocumentTelechargementService,
    protected translationService: TranslationService,
    protected translate: TranslateService,
    protected mainMobileComponent: MainMobileComponent,
    protected etudiantService: EtudiantService
  ) {
    super(activatedRoute, documentService, documentTelechargementService, translationService, translate, etudiantService);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.mainMobileComponent.setIconKey(this.iconKey).setTitleKey(this.titleKey);
  }
}
