export class MenuInfo {

  constructor(id: string, libelle: string, ordre: number, targetPath: string, cssClass: string, iconName: string) {
    this.id = id;
    this.libelle = libelle;
    this.ordre = ordre;
    this.targetPath = targetPath;
    this.cssClass = cssClass;
    this.iconName = iconName;
  }

  id: string;
  libelle: string;
  ordre: number;
  targetPath: string;
  cssClass: string;
  iconName: string;
}
