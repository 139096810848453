import {Inject, Injectable} from '@angular/core';
import {APP_BASE_HREF} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AppUrlService {

  private readonly appUrl: string;

  constructor(@Inject(APP_BASE_HREF) private baseHref: string) {
    this.appUrl = window.location.origin.concat(baseHref);
  }

  public getAppUrl(): string {
    return this.appUrl;
  }

}
