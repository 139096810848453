export class ErrorButtonDescription {

  private key: string;

  public getKey(): string {
    return this.key;
  }

  public setKey(key: string): ErrorButtonDescription {
    this.key = key;
    return this;
  }

}
