export class ContactInfos {
  private typeMoyenCommunication: string;
  private donneesRenseignees: string;
  private principal: boolean;

  public getTypeMoyenCommunication(): string {
    return this.typeMoyenCommunication;
  }

  public setTypeMoyenCommunication(typeMoyenCommunication: string){
    this.typeMoyenCommunication = typeMoyenCommunication;
    return this;
  }

  public getDonneesRenseignees(): string {
    return this.donneesRenseignees;
  }

  public setDonneesRenseignees(donneesRenseignees: string){
    this.donneesRenseignees = donneesRenseignees;
    return this;
  }

  public isPrincipal(): boolean {
    return this.principal;
  }

  public setPrincipal(principal: boolean){
    this.principal = principal;
    return this;
  }

}
