import {FrontErrorMessages} from '../front-error-messages';
import {FrontErrorRequest} from './front-error-request';

export class FrontErrorNoResponse extends FrontErrorRequest {
  constructor() {
    super();
    this.setInternalErrorMessages(
        new FrontErrorMessages()
          .setTitle('Echec d\'une requête')
          .setMessage('Impossible de communiquer avec le serveur, il n\'a pas répondu à la requête')
          .setHints('Serveur hors service')
      );
  }
}
