import {FrontErrorMessages} from '../front-error-messages';
import {FrontErrorRedirection} from '../front-error-redirection';
import {GuiRoutes} from '../../../gui-routes';

export class FrontErrorFromBack extends FrontErrorRedirection {

  constructor(errorMessage: string) {
    super();
    this.setRedirectionRoute([GuiRoutes.CONNEXION_REFUSEE])
      .setNotificationsEnabled(false)
      .setInternalErrorMessages(
        new FrontErrorMessages()
          .setTitle('Erreur serveur')
          .setMessage(errorMessage)
          .setHints('Une erreur ou une exception a été levée par le back')
    )
      .setUserErrorMessages(
        new FrontErrorMessages().setMessage(errorMessage)
      );
  }
}
