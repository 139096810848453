import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of as observableOf} from 'rxjs';
import {AuthentificationService} from '../../authentication/authentification.service';
import {catchError, map} from 'rxjs/operators';
import {StorageConstants} from '../../storage/storage-constants';
import {CasTicketValidationResponse} from '../../../models/cas-ticket-validation-response';
import {FrontErrorService} from '../../error/front-error.service';
import {FrontErrorFromBack} from '../../error/errors/front-error-from-back';
import {ChildrenGuard} from '../children/children.guard';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard extends ChildrenGuard {
  constructor(
    protected router: Router,
    protected authentificationService: AuthentificationService,
    protected frontErrorService: FrontErrorService) {
    super();
  }

  private static initCasTicketFromRoute(route): string {
    const casTicketFromRoute = route.queryParamMap.get('ticket');
    if (casTicketFromRoute) {
      sessionStorage.setItem(StorageConstants.TOKEN_NAME_CAS, casTicketFromRoute);
    }
    return casTicketFromRoute;
  }

  private static initCasTicket(route: ActivatedRouteSnapshot): string {
    const casTicketFromSessionStorage: string = sessionStorage.getItem(StorageConstants.TOKEN_NAME_CAS);
    return casTicketFromSessionStorage ? casTicketFromSessionStorage : LoginGuard.initCasTicketFromRoute(route);
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const casTicket: string = LoginGuard.initCasTicket(route);
    if (!casTicket) {
      this.authentificationService.initCASAuthentification();
      return false;
    } else {
      const backendToken = sessionStorage.getItem(StorageConstants.TOKEN_NAME_PN);
      if (!backendToken) {
        return this.authentificationService.validateCASTicket(casTicket).pipe(
          map((response: CasTicketValidationResponse) => {
            if (!response.authentified) {
              this.authentificationService.cleanAllSessionTokens();
              this.frontErrorService.handleError(new FrontErrorFromBack(response.message));
            }
            return response.authentified;
          }),
          catchError(() => {
            this.authentificationService.cleanAllSessionTokens();
            return observableOf(false);
          }));
      }
    }
    return true;
  }
}
