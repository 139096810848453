import {Router} from '@angular/router';
import {InjectableLocatorService} from '../locator/injectable-locator.service';
import {FrontErrorMessages} from './front-error-messages';

export abstract class FrontError {

  // private type: string;
  // private applicationId: string;
  // private user: string;
  // private time: string;
  // private userAgent: string;
  // private stack: string;
  private url: string;
  private userErrorMessages: FrontErrorMessages;
  private internalErrorMessages: FrontErrorMessages;
  private router: Router;

  protected constructor() {
    this.router = InjectableLocatorService.injector.get<Router>(Router);
    this.url = window.location.origin.concat(this.router.url);
  }

  public getUrl(): string {
    return this.url;
  }

  public setUrl(url: string): FrontError {
    this.url = url;
    return this;
  }

  public getUserErrorMessages(): FrontErrorMessages {
    return this.userErrorMessages;
  }

  public setUserErrorMessages(userErrorMessages: FrontErrorMessages): FrontError {
    this.userErrorMessages = userErrorMessages;
    return this;
  }

  public getInternalErrorMessages(): FrontErrorMessages {
    return this.internalErrorMessages;
  }

  public setInternalErrorMessages(internalErrorMessages: FrontErrorMessages): FrontError {
    this.internalErrorMessages = internalErrorMessages;
    return this;
  }

  public setRouter(router: Router): FrontError {
    this.router = router;
    return this;
  }

  public getRouter(): Router {
    return this.router;
  }

  public abstract process(): void;

}
