import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Etudiant} from '../models/etudiant';
import {IndividuEtudiantInfos} from '../models/individu-etudiant-infos';
import {Inscription} from '../models/inscription';
import {AuthentificationTokenService} from '../technique/authentication/authentification-token.service';
import {BackendHttpClient} from '../technique/http-client/backend-http-client.service';

@Injectable({
  providedIn: 'root'
})
export class EtudiantService {

  constructor(
    private http: BackendHttpClient,
    private authentificationTokenService: AuthentificationTokenService
  ) {}

  public getEtudiant(): Observable<Etudiant> {
    return this.http.get<Etudiant>('sve/etudiant/' +  this.authentificationTokenService.getEtudiantId());
  }

  public getInscriptions(): Observable<Array<Inscription>> {
    return this.http.get<Array<Inscription>>('sve/etudiant/' +  this.authentificationTokenService.getEtudiantId() + '/inscriptions');
  }

  public getInformations(): Observable<IndividuEtudiantInfos> {
    return this.http.get<IndividuEtudiantInfos>('sve/etudiant/' +  this.authentificationTokenService.getEtudiantId() + '/infos');
  }

}
