import {Component, OnInit} from '@angular/core';
import {InfoApplicationService} from '../../service/info-application.service';
import {InfoApplication} from '../../models/info-application';

@Component({
  selector: 'app-version',
  templateUrl: './app-version.component.html',
  styleUrls: ['./app-version.component.scss']
})
export class AppVersionComponent implements OnInit {

  appName: string;
  versionApp: string;
  nomBdd: string;
  annee: number;

  constructor(private infoAppService: InfoApplicationService) {}

  public ngOnInit(): void {
    this.infoAppService.getInfoApplication()
      .subscribe(
        (infoApplication: InfoApplication) => {
          this.appName = infoApplication.nomApplication;
          this.versionApp = infoApplication.numeroVersion;
          this.nomBdd = infoApplication.nomBaseDeDonnees;
        });
    this.annee = new Date().getFullYear();
  }

}
