import {Injectable, Injector} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InjectableLocatorService {

  public static injector: Injector;

}
