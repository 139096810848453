import {FrontError} from './front-error';

export class FrontErrorLogging extends FrontError {

  private loggingEnabled: boolean;

  constructor() {
    super();
    this.loggingEnabled = true;
  }

  public setLoggingEnabled(loggingEnabled: boolean): FrontErrorLogging {
    this.loggingEnabled = loggingEnabled;
    return this;
  }

  public isLoggingEnabled(): boolean {
    return this.loggingEnabled;
  }

  public log(): void {
    if (this.loggingEnabled) {
      this.logAction();
    }
  }

  public logAction(): void {
    console.error(this);
  }

  public process(): void {
    this.log();
  }

}
