import {Injectable} from '@angular/core';
import {HttpClient, HttpHandler} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppHttpClient extends HttpClient {

  private urlPrefix: string;

  constructor(protected httpHandler: HttpHandler) {
    super(httpHandler);
    this.urlPrefix = '';
  }

  public getUrlPrefix(): string {
    return this.urlPrefix;
  }

  public setUrlPrefix(urlPrefix: string) {
    this.urlPrefix = urlPrefix;
  }

  public requestUrl(url: string): string {
    return this.urlPrefix.concat(url);
  }

  public get<T>(url: string, options?: any): Observable<T> {
    // @ts-ignore
    return super.get<T>(this.requestUrl(url), options);
  }

  public post<T>(url: string, body: any, options?: any): Observable<T> {
    // @ts-ignore
    return super.post<T>(this.requestUrl(url), body, options);
  }

  public patch<T>(url: string, body: any, options?: any): Observable<T> {
    // @ts-ignore
    return super.patch<T>(this.requestUrl(url), body, options);
  }
}
