import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transformLongsLibelles'
})
export class TransformLongsLibellesPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    value = value.replace(/[/]/g, '\u200B/\u200B');
    value = value.replace(/[-]/g, '\u200B-\u200B');
    return value;
  }

}
