import {FrontErrorLogging} from './front-error-logging';
import {NotificationService} from '../../view/notifications/notification-service/notification-service';
import {InjectableLocatorService} from '../locator/injectable-locator.service';
import {FrontErrorMessages} from './front-error-messages';

export class FrontErrorNotify extends FrontErrorLogging {

  private notificationsEnabled: boolean;
  private notificationService: NotificationService;

  constructor() {
    super();
    this.notificationsEnabled = true;
    this.notificationService = InjectableLocatorService.injector.get<NotificationService>(NotificationService);
  }

  public notify(): void {
    if (this.notificationsEnabled) {
      this.notifyAction();
    }
  }

  public setNotificationsEnabled(notificationsEnabled: boolean): FrontErrorNotify {
    this.notificationsEnabled = notificationsEnabled;
    return this;
  }

  public isNotificationEnabled(): boolean {
    return this.notificationsEnabled;
  }

  public setNotificationService(notificationService: NotificationService): FrontErrorNotify {
    this.notificationService = notificationService;
    return this;
  }

  protected getNotificationService() {
    return this.notificationService;
  }

  public notifyAction(): void {
    const errorMessages: FrontErrorMessages = this.getUserErrorMessages();
    this.notificationService.error(errorMessages.getMessage(), errorMessages.getTitle());
  }

  public process(): void {
    super.process();
    this.notify();
  }

}
