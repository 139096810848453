import {AfterViewInit, Component, ElementRef, Input, OnInit} from '@angular/core';
import {Form, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ContactService} from 'src/app/service/contact.service';
import {Observable, throwError} from 'rxjs';
import {catchError, debounceTime, switchMap} from 'rxjs/operators';
import {Pays} from 'src/app/models/pays';
import {Commune} from 'src/app/models/commune';
import {map, startWith} from 'rxjs/operators';
import { Adresse } from 'src/app/models/adresse';
import { NotificationService } from '../../notifications/notification-service/notification-service';
import { R3TargetBinder } from '@angular/compiler';

@Component({
    selector: 'app-infos-dialog-adresse',
    templateUrl: 'dialog-adresse.component.html',
    styleUrls: ['./dialog-adresse.component.scss']
  })
  export class AdresseDialogComponent implements OnInit, AfterViewInit {

    @Input() adresse: Adresse;
    adresseForm: UntypedFormGroup;
    listPays: Array<Pays>;
    listCommunes: Array<Commune> [];
    filteredPaysOptions: Observable<Pays[]>;
    filteredCodePostalOptions: Observable<Commune[]>;

    
    
    constructor(
      protected translateService: TranslateService,
      protected contactService: ContactService,
      protected notificationService: NotificationService,
      private el: ElementRef
      ) {}
      
      public ngAfterViewInit(): void {
        this.initialisationInputBlur();
      }

      public ngOnInit(): void {

        //init Adresseform - composant
        this.adresseForm = new UntypedFormGroup({
        adresse1: new UntypedFormControl('', [Validators.required, Validators.maxLength(100)]),
        adresse2: new UntypedFormControl('', [Validators.maxLength(300)]),
        boitePostale: new UntypedFormControl('', [Validators.maxLength(60)]),
        codePostal: new UntypedFormControl('', [Validators.required,  Validators.minLength(5),Validators.maxLength(5)]),
        codePostalEtranger: new UntypedFormControl('', [Validators.minLength(0),Validators.maxLength(10)]),
        ville: new UntypedFormControl('', [Validators.required, Validators.maxLength(60)]),
        pays: new UntypedFormControl('', [Validators.required]),
        });

        //init autocomplete
        this.getPays().subscribe((pays: Array<Pays>) => {
          this.listPays = pays;
          this.filteredPaysOptions = this.getPaysForm().valueChanges
          .pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.libelleLong),
            map(libelleLong => libelleLong ? this._filterPays(libelleLong) : this.listPays.slice())
            );
          });
          
        this.filteredCodePostalOptions = this.getCodePostalForm().valueChanges
        .pipe(
          debounceTime(300),
          switchMap(value => this.searchCommunes(this.getCodePostalForm().value))
          );

        //init Adresseform - data
        this.getAdresse1Form().setValue(this.adresse.adresse1);
        this.getAdresse2Form().setValue(this.adresse.adresse2);
        this.getBoitePostaleForm().setValue(this.adresse.boitePostale);
        this.getPaysForm().setValue(this.adresse.pays);
        this.majComponentVille();
        if (this.isPaysFranceSelectionne()) {
          this.searchCommunes(this.adresse.codePostal).subscribe((communes: Array<Commune>) => {
            if (communes && communes.length > 0) {
            this.getCodePostalForm().setValue(communes[0]);
            this.getVilleForm().setValue(communes[0].libelleLong);
          }
        });
          
        } else {
          this.getCodePostalEtrangerForm().setValue(this.adresse.codePostalEtranger);
          this.getVilleForm().setValue(this.adresse.ville);
        }
    }



    // AUTOCOMPLETE PAYS ET COMMUNE
    
    public displayPaysFn(pays: Pays): string {
      return pays && pays.libelleLong ? pays.libelleLong : '';
    }
    
    private _filterPays(name: string): Pays[] {
      this.majComponentVille();
      const filterValue = name.toLowerCase();
      
      return this.listPays.filter(option => option.libelleLong.toLowerCase().indexOf(filterValue) === 0);
    }
    
    public displayCodePostalFn(commune: Commune): string {
      return commune && commune.codePostal ? commune.codePostal : '';
    }

    public initialisationInputBlur() {
      let input = this.el.nativeElement.querySelector('#inputCodePostal');
      if (input) {
          input.onblur = (event) => {
          let saisieCodePostal = event.target.value;
          if (this.isPaysFranceSelectionne() && saisieCodePostal && saisieCodePostal.length === 5) {
            this.searchCommunes(saisieCodePostal).subscribe((communes: Array<Commune>) => {
                if (communes && communes.length > 0) {
                  if (!this.getVilleForm().value) {
                    this.getCodePostalForm().setValue(communes[0]);
                    this.getVilleForm().setValue(communes[0].libelleLong);
                  } else {
                    let communesTrouvees: Commune[] = communes.filter(option => option.libelleLong.indexOf(this.getVilleForm().value) === 0);
                    if (communesTrouvees && communesTrouvees.length == 0) {
                      this.getCodePostalForm().setValue(communes[0]);
                      this.getVilleForm().setValue(communes[0].libelleLong);
                    }
                  }
              } else {
                this.getCodePostalForm().reset();
                this.getVilleForm().reset();
              }
            });
          } else {
            this.getVilleForm().reset();
          }
        };
      }
    }

    // FORMULAIRE
    getAdresse1Form() { return this.adresseForm.get('adresse1'); }
    getAdresse2Form() { return this.adresseForm.get('adresse2'); }
    getBoitePostaleForm() { return this.adresseForm.get('boitePostale'); }
    getCodePostalForm() { return this.adresseForm.get('codePostal'); }
    getCodePostalEtrangerForm() { return this.adresseForm.get('codePostalEtranger'); }
    getVilleForm() { return this.adresseForm.get('ville'); }
    getPaysForm() { return this.adresseForm.get('pays'); }

    public getAdresse1ErrorMessage() {
      if (this.getAdresse1Form().hasError('required')) {
        return this.translateService.instant('libelle.infos.data.adresses.dialog.errors.adresse');
      }
    }

    public getCodePostalErrorMessage() {
      if (this.getCodePostalForm().hasError('required')) {
        return this.translateService.instant('libelle.infos.data.adresses.dialog.errors.codepostalrequis');
      }
      if (this.getCodePostalForm().hasError('minlength')) {
        return this.translateService.instant('libelle.infos.data.adresses.dialog.errors.codepostalmin');
      }
    }

    public getVilleErrorMessage() {
      if (this.getVilleForm().hasError('required')) {
        return this.translateService.instant('libelle.infos.data.adresses.dialog.errors.ville');
      }
    }

    public getPaysErrorMessage() {
      if (this.getPaysForm().hasError('required')) {
        return this.translateService.instant('libelle.infos.data.adresses.dialog.errors.pays');
      }
    }

    // CONTROLES ET ACTIONS

    public onKeyCodePostal(event: any) {
      if (event.keyCode == 13) {
        this.codePostalSelected(this.getCodePostalForm().value);
      }
    }

    public onKeyPays(event: any) {
      if (event.keyCode == 13) {
        this.majComponentVille();
      }
    }

    public isAdresseFormValid() {
      let isValid: boolean = true;
      if (this.getAdresse1Form().invalid) {
        isValid = false;
      }
      if (this.getPaysForm().invalid) {
        isValid = false;
      }
      if (this.getVilleForm().invalid) {
        isValid = false;
      }
      if (this.getCodePostalForm().invalid && this.isPaysFranceSelectionne()) {
        isValid = false;
      }
      return isValid;
    }

    public resetPays() {
      if (this.isPaysFranceSelectionne()) {
        this.resetCodePostal();
      }
      this.getCodePostalEtrangerForm().reset();
      this.getPaysForm().setValue('');
    }

    public resetCodePostal() {
      if (this.isPaysFranceSelectionne()) {
        this.getVilleForm().reset();
      }
      this.getCodePostalForm().reset();
    }

    public majComponentVille() {
      if (this.getPaysForm && this.getPaysForm().value.codePays === '100') {
        this.getCodePostalEtrangerForm().reset();
        this.getVilleForm().disable();
      } else {
        this.getVilleForm().enable();
      }
      this.getCodePostalForm().reset();
      this.getVilleForm().reset();
      this.initialisationInputBlur();
    }

    public codePostalSelected(commune:Commune) {
      this.getVilleForm().setValue(commune.libelleLong);
    }

    public isPaysFranceSelectionne() {
      return this.getPaysForm().value && this.getPaysForm().value.codePays === '100';
    }

    public modifierCoordoneesPersonnelles(){
      this.adresse.adresse1 = this.getAdresse1Form().value;
      this.adresse.adresse2 = this.getAdresse2Form().value;
      if (this.isPaysFranceSelectionne()) {
        this.adresse.codePostal = this.getCodePostalForm().value.codePostal;
        this.adresse.codePostalEtranger = null;
      } else {
        this.adresse.codePostal = null;
        this.adresse.codePostalEtranger = this.getCodePostalEtrangerForm().value;
      }
      
      this.adresse.boitePostale = this.getBoitePostaleForm().value;
      this.adresse.ville = this.getVilleForm().value;
      this.adresse.pays = this.getPaysForm().value;
      this.updateAdresse(this.adresse).subscribe(
        (val) => {
          this.notificationService.success(this.translateService.instant('libelle.infos.data.adresses.dialog.notifications.success'));
        },
        response => {
          this.notificationService.error(this.translateService.instant('libelle.infos.data.adresses.dialog.notifications.error'));
        });
    }

    // SERVICES
    public getPays(): Observable<Array<Pays>> {
      return this.contactService
      .getPays()
      .pipe(catchError(err => throwError(new Error(err.error.message))));
    }
    
    public searchCommunes(codePostal: string): Observable<Array<Commune>> {
      return this.contactService
        .searchCommunes(codePostal)
        .pipe(catchError(err => throwError(new Error(err.error.message))));
    }

    public updateAdresse(adresse: Adresse) {
      return this.contactService
      .updateAdresse(adresse);
    }
      
  }