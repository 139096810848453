import {IUser} from 'ng-cocktail-ui';

export class User implements IUser {
  email: string;
  firstname: string;
  fullname: string;
  lastname: string;
  username: string;
  id:string;
}
