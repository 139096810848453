import { Injectable } from '@angular/core';
import {AppHttpClient} from './app-http-client.service';
import {HttpHandler} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BackendHttpClient extends AppHttpClient {

  constructor(httpHandler: HttpHandler) {
    super(httpHandler);
    this.setUrlPrefix(environment.urlBackend);
  }
}
