import {Component} from '@angular/core';
import {ComponentsInfosService} from '../../../service/components-infos.service';
import {AccueilComponent} from '../accueil-component';

@Component({
  selector: 'app-accueil',
  templateUrl: './accueil-desktop.component.html',
  styleUrls: ['./accueil-desktop.component.scss']
})
export class AccueilDesktopComponent extends AccueilComponent  {

  constructor(protected componentInfoService: ComponentsInfosService) {
    super(componentInfoService);
  }
}
