import {FrontErrorRedirection} from '../front-error-redirection';
import {GuiRoutes} from '../../../gui-routes';
import {FrontErrorMessages} from '../front-error-messages';

export class FrontErrorUnknownPage extends FrontErrorRedirection {

  constructor() {
    super();
    this.setRedirectionRoute([GuiRoutes.PAGE_INCONNUE])
      .setNotificationsEnabled(false)
      .setInternalErrorMessages(
        new FrontErrorMessages()
          .setTitle('Tentative d\'accès à un path inconnu')
          .setMessage('Cette erreur a été levé après une tentative d\'accès à un path non bindé dans le module de routing')
          .setHints('Le path n\'existe pas ou est mal configuré/orthographié')
      )
      .setUserErrorMessages(
        new FrontErrorMessages()
          .setTitle('Tentative d\'accès à une page web inconnue')
          .setMessage('La page où vous avez tenté d\'accèder n\'existe pas ou plus.')
          .setHints('Vérifiez que l\'url que vous souhaitez visiter est correcte.')
      );
  }

}
