import {Injectable} from '@angular/core';
import {GuiRoutes} from '../../gui-routes';
import {UrlConverter} from '../url/url-converter';

@Injectable({
  providedIn: 'root'
})
export class RouteService {

  private readonly mobileRoutesToDesktop: Map<string, Array<string>>;
  private readonly desktopRoutesToMobile: Map<string, Array<string>>;
  private readonly defaultMobileRoute: Array<string>;
  private readonly defaultDesktopRoute: Array<string>;

  constructor() {
    this.defaultMobileRoute = new Array<string>(GuiRoutes.MOBILE);
    this.defaultDesktopRoute = new Array<string>(GuiRoutes.ACCUEIL);
    this.mobileRoutesToDesktop = new Map<string, Array<string>>();
    this.desktopRoutesToMobile = new Map<string, Array<string>>();
  }

  private static getOrDefault(route: Array<string>, defaultRoute: Array<string>): Array<string> {
    return route ? route : defaultRoute;
  }

  public getMobileRoutesToDesktop(): Map<string, Array<string>> {
    return this.mobileRoutesToDesktop;
  }

  public getDesktopRoutesToMobile(): Map<string, Array<string>> {
    return this.desktopRoutesToMobile;
  }

  public setDesktopToMobile(desktopUrl: string, mobileRoute: Array<string>): void {
    this.desktopRoutesToMobile.set(desktopUrl, mobileRoute);
  }

  public setMobileToDesktop(mobileUrl: string, desktopRoute: Array<string>): void {
    this.mobileRoutesToDesktop.set(mobileUrl, desktopRoute);
  }

  public setCorrespondingRoutes(desktopUrl: string, mobileRoute: Array<string>, mobileUrl: string, desktopRoute: Array<string>): void {
    this.setDesktopToMobile(desktopUrl, mobileRoute);
    this.setMobileToDesktop(mobileUrl, desktopRoute);
  }

  public setStandardCorrespondingRoutes(desktopUrl: string): void {
    this.setDesktopToMobile(desktopUrl, UrlConverter.desktopUrlToMobileRoute(desktopUrl));
    this.setMobileToDesktop(UrlConverter.desktopUrlToMobileUrl(desktopUrl), UrlConverter.urlToRoute(desktopUrl));
  }

  public mobileRoute(desktopUrl: string): Array<string> {
    return this.desktopRoutesToMobile.get(desktopUrl);
  }

  public desktopRoute(mobileUrl: string): Array<string> {
    return this.mobileRoutesToDesktop.get(mobileUrl);
  }

  public mobileRouteOrDefault(desktopUrl: string): Array<string> {
    return RouteService.getOrDefault(this.mobileRoute(desktopUrl), this.defaultMobileRoute);
  }

  public desktopRouteOrDefault(mobileUrl: string): Array<string> {
    return RouteService.getOrDefault(this.desktopRoute(mobileUrl), this.defaultDesktopRoute);
  }

}
