export class MenuItemInfo {

  private labelKey: string;
  private icon: string;

  public getLabelKey(): string {
    return this.labelKey;
  }

  public setLabelKey(label: string): MenuItemInfo {
    this.labelKey = label;
    return this;
  }

  public getIcon(): string {
    return  this.icon;
  }

  public setIcon(icon: string): MenuItemInfo {
    this.icon = icon;
    return this;
  }

}
