import {Inscription} from './inscription';
import {Document} from './document';

export class DocumentInscriptionInfos {

  private documentInfos: Document;
  private inscriptionInfos: Inscription;

  public getDocumentInfos(): Document {
    return this.documentInfos;
  }

  public setDocumentInfos(documentInfos: Document): DocumentInscriptionInfos {
    this.documentInfos = documentInfos;
    return this;
  }

  public getInscriptionInfos(): Inscription {
    return this.inscriptionInfos;
  }

  public setInscriptionInfos(inscriptionInfos: Inscription): DocumentInscriptionInfos {
    this.inscriptionInfos = inscriptionInfos;
    return this;
  }

}
