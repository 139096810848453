import {ChildrenGuard} from '../children/children.guard';
import {ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ComponentsInfosService} from '../../../service/components-infos.service';

export abstract class UrlGuard extends ChildrenGuard {

  protected constructor(protected componentsInfosService: ComponentsInfosService) {
    super();
  }

  protected abstract guardAction(state: RouterStateSnapshot): boolean;

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.componentsInfosService.refreshComponentInfos().pipe(
      map(() => {
        return this.guardAction(state);
      })
    );
  }

}
