import {FrontErrorRedirection} from '../front-error-redirection';
import {FrontErrorMessages} from '../front-error-messages';
import {GuiRoutes} from '../../../gui-routes';

export class FrontErrorRequest extends FrontErrorRedirection {
  constructor() {
    super();
    this.setRedirectionRoute([GuiRoutes.ERREUR])
      .setNotificationsEnabled(false)
      .setInternalErrorMessages(
        new FrontErrorMessages()
          .setTitle('Echec d\'une requête')
          .setMessage('Un problème est survenu lors de la communication avec le serveur')
          .setHints('Serveur HS, configuration de l\'URL du serveur incorrecte, path de la requête vers le back incorrecte')
      ).setUserErrorMessages(
        new FrontErrorMessages()
          .setTitle('Erreur de connexion')
          .setMessage('Un problème est survenu lors de la communication avec le serveur.')
          .setHints('Contactez l\'administrateur du site pour obtenir de l\'aide.')
     );
  }

}
