import {Component} from '@angular/core';
import {GuiRoutes} from '../../gui-routes';
import {ErrorButtonDescription} from '../erreur/error-button-description';
import {Router} from '@angular/router';
import {ErreurBase} from '../erreur/erreur-base';
import {AuthentificationService} from '../../technique/authentication/authentification.service';

@Component({
  selector: 'app-erreur-defaut',
  templateUrl: './erreur-defaut.component.html',
  styleUrls: ['./erreur-defaut.component.scss']
})
export class ErreurDefautComponent extends ErreurBase {
  readonly buttonHome: ErrorButtonDescription;
  readonly buttonSignOut: ErrorButtonDescription;

  constructor(private router: Router, private authService: AuthentificationService) {
    super();
    this.buttonHome = new ErrorButtonDescription().setKey('error.button.defaut.retour-accueil');
    this.buttonSignOut = new ErrorButtonDescription().setKey('error.button.defaut.deconnexion-cas');
    this.buttonErrorDescriptionList.push(this.buttonHome, this.buttonSignOut);
  }

  public resolveError($event: ErrorButtonDescription): void {
    switch ($event) {
      case this.buttonHome :
        this.router.navigate([GuiRoutes.ACCUEIL]);
        break;

      case this.buttonSignOut :
        this.authService.signOut();
        break;

      default : break;
    }
  }
}
