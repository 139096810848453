import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AccueilDesktopComponent} from './view/accueil/desktop/accueil-desktop.component';
import {NotesDesktopComponent} from './view/notes/desktop/notes-desktop.component';
import {AppCommonModule} from './app-common.module';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatCardModule} from '@angular/material/card';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {FormsModule, ReactiveFormsModule,} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NotificationService} from './view/notifications/notification-service/notification-service';
import {ConfirmationDialogComponent} from './view/notifications/confirmation-dialog/confirmation-dialog.component';
import {MessageDialogService} from './view/notifications/message-dialog.service';
import {AlertDialogComponent} from './view/notifications/alert-dialog/alert-dialog.component';
import {LoadingScreenInterceptor} from './view/loading-screen-service/loading.interceptor';
import {LoadingScreenComponent} from './view/loading-screen/loading-screen.component';
import {LoadingScreenManuelComponent} from './view/loading-screen-manuel/loading-screen-manuel.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DropDownMenuComponent} from './view/drop-down-menu/drop-down-menu.component';
import {DropDownMenuItemComponent} from './view/drop-down-menu-item/drop-down-menu-item.component';
import {AppVersionComponent} from './view/app-version/app-version.component';
import {MainDesktopComponent} from './view/main/desktop/main-desktop.component';
import {LoginComponent} from './view/login/login.component';
import {ErreurComponent} from './view/erreur/erreur.component';
import {ErreurDefautComponent} from './view/erreur-defaut/erreur-defaut.component';
import {ReconnexionComponent} from './view/reconnexion/reconnexion.component';
import {ErreurLayoutComponent} from './view/erreur-layout/erreur-layout.component';
import {PageNonTrouveeComponent} from './view/page-non-trouvee/page-non-trouvee.component';
import {ErreurConnexionRefuseeComponent} from './view/erreur-connexion-refusee/erreur-connexion-refusee.component';
import {PageInconnueComponent} from './view/page-inconnue/page-inconnue.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {DocumentsDesktopComponent} from './view/documents/desktop/documents-desktop.component';
import {APP_BASE_HREF, PlatformLocation} from '@angular/common';
import {MainMobileComponent} from './view/main/mobile/main-mobile.component';
import {DeviceDetectorService} from 'ngx-device-detector';
import {DocumentsMobileComponent} from './view/documents/mobile/documents-mobile.component';
import {AccueilMobileComponent} from './view/accueil/mobile/accueil-mobile.component';
import {NotesMobileComponent} from './view/notes/mobile/notes-mobile.component';
import {WebpackTranslateLoader} from './technique/translation/webpack-translate-loader';
import {TranslationService} from './technique/translation/translation.service';
import {InfosDesktopComponent} from './view/infos/desktop/infos-desktop.component';
import {InfosMobileComponent} from './view/infos/mobile/infos-mobile.component';
import {AdresseDialogComponent} from './view/infos/dialog/dialog-adresse.component';
import {TelephoneDialogComponent} from './view/infos/dialog/telephone/dialog-telephone.component';
import {EmailDialogComponent} from './view/infos/dialog/email/dialog-email.component';
import { NgCocktailUiModule } from 'ng-cocktail-ui';

@NgModule({
    declarations: [
        AppComponent,
        AccueilDesktopComponent,
        AccueilMobileComponent,
        DocumentsDesktopComponent,
        DocumentsMobileComponent,
        NotesDesktopComponent,
        NotesMobileComponent,
        InfosDesktopComponent,
        InfosMobileComponent,
        AdresseDialogComponent,
        TelephoneDialogComponent,
        EmailDialogComponent,
        LoadingScreenComponent,
        ConfirmationDialogComponent,
        AlertDialogComponent,
        LoadingScreenManuelComponent,
        DropDownMenuComponent,
        DropDownMenuItemComponent,
        AppVersionComponent,
        MainDesktopComponent,
        MainMobileComponent,
        LoginComponent,
        ErreurComponent,
        ErreurLayoutComponent,
        ErreurDefautComponent,
        ReconnexionComponent,
        PageNonTrouveeComponent,
        ErreurConnexionRefuseeComponent,
        PageInconnueComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        AppCommonModule,
        HttpClientModule,
        MatRadioModule,
        MatCheckboxModule,
        MatDialogModule,
        MatButtonModule,
        MatCardModule,
        MatTableModule,
        MatTabsModule,
        MatFormFieldModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatSelectModule,
        MatAutocompleteModule,
        FontAwesomeModule,
        NgCocktailUiModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (translationService: TranslationService) => new WebpackTranslateLoader(translationService),
                deps: [TranslationService]
            }
        })
    ],
    providers: [
        {
            provide: APP_BASE_HREF,
            useFactory: (platformLocation: PlatformLocation) => platformLocation.getBaseHrefFromDOM(),
            deps: [PlatformLocation]
        },
        NotificationService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingScreenInterceptor,
            multi: true
        },
        MessageDialogService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
