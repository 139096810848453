import {Component, OnInit} from '@angular/core';
import {InfosComponent} from '../infos-component';
import {ActivatedRoute, Params} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {EtudiantService} from '../../../service/etudiant.service';
import {ContactService} from 'src/app/service/contact.service';
import { MatDialog } from '@angular/material/dialog';
import {ContactInfos} from 'src/app/models/contact-infos';

@Component({
  selector: 'app-infos-mobile',
  templateUrl: './infos-mobile.component.html',
  styleUrls: ['./infos-mobile.component.scss']
})
export class InfosMobileComponent extends InfosComponent {

 
  constructor(
    protected activatedRoute: ActivatedRoute,
    protected translateService: TranslateService,
    protected etudiantService: EtudiantService,
    protected contactService: ContactService,
    public dialog: MatDialog
  ) {
    super(activatedRoute, translateService, etudiantService, contactService, dialog);
  }

  getBoutonModifierContactLabel(contactInfos : ContactInfos){
    if (contactInfos.getTypeMoyenCommunication() == 'telephone' || contactInfos.getTypeMoyenCommunication() == 'email') {
      return this.translateService.instant('libelle.infos.data.contacts.boutons.modifier');
    }
  }
}
