export enum GuiRoutes {
  ALL_OTHERS = '**',
  ROOT = '',
  DOCUMENTS = 'documents/:categorie',
  NOTES = 'notes',
  ERREUR = 'erreur',
  INFOS = 'infos',
  ACCUEIL = 'accueil',
  RECONNEXION = 'reconnexion',
  PAGE_INCONNUE = 'page-inconnue',
  CONNEXION_REFUSEE = 'connexion-refusee',
  MOBILE = 'mobile',
}

