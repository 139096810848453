import { OnInit, Directive } from '@angular/core';
import {MenuInfo} from '../../models/menu-info';
import {ComponentsInfosService} from '../../service/components-infos.service';

@Directive()
export class AccueilComponent implements OnInit {

  menuInfos: Array<MenuInfo>;

  constructor(protected componentInfoService: ComponentsInfosService) {
    this.menuInfos = new Array<MenuInfo>();
  }

  public buttonClickSideMenuIdRedirection(menuElementId: string): void {
    document.getElementById(menuElementId).click();
  }

  public getMenuInfos(): void {
    this.componentInfoService.refreshComponentInfos().subscribe(
      (menuInfos: Array<MenuInfo>) => {
        this.menuInfos = menuInfos;
      },
      (err) => {
        console.log(err.message);
      }
    );
  }

  public ngOnInit(): void {
    this.getMenuInfos();
  }
}
