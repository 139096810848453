import {Injectable} from '@angular/core';
import * as jwtDecode from 'jwt-decode';
import {StorageConstants} from '../storage/storage-constants';
import {AuthentificationConstants} from './authentification-constants';
import {Token} from './token';

@Injectable()
export class AuthentificationTokenService {

  public getToken(): string {
    return sessionStorage.getItem(StorageConstants.TOKEN_NAME_PN);
  }

  public enregistrerTokenDansStorage(token: string): void {
    if (token) {
      sessionStorage.setItem(StorageConstants.TOKEN_NAME_PN, token);
    }
  }

  public isTokenValid(): boolean {
    return this.isTokenValidFromParam(this.getToken());
  }

  public isTokenValidFromParam(token: string): boolean {
    return token && this.getTimestampFromToken(token) > new Date().getTime();
  }

  public getTimestampFromToken(token: string): number {
    let expirationTimestamp;
    try {
      expirationTimestamp = jwtDecode<Token>(token).exp;
    } catch (e) {
      console.error('ExpirationTimestamp decode failed.', e);
    }
    return expirationTimestamp * AuthentificationConstants.SECOND_IN_MILLIS;
  }

  public getUserName(): string {
    let userName;
    try {
      userName = jwtDecode<Token>(this.getToken()).sub;
    } catch (e) {
      console.error('UserName decode failed.', e);
    }
    return userName;
  }

  public getEtudiantId(): string {
    let etudiantId;
    try {
      etudiantId = jwtDecode<Token>(this.getToken()).ETUDIANT_ID;
    } catch (e) {
      console.error('EtudiantId decode failed : ', e);
    }
    return etudiantId;
  }

  public getPersId(): string {
    let persId;
    try {
      persId = jwtDecode<Token>(this.getToken()).PERS_ID;
    } catch (e) {
      console.error('PersId decode failed : ', e);
    }
    return persId;
  }
}
