import {Component} from '@angular/core';
import {GuiRoutes} from '../../gui-routes';
import {ErrorButtonDescription} from '../erreur/error-button-description';
import {Router} from '@angular/router';
import {AuthentificationService} from '../../technique/authentication/authentification.service';
import {ErreurBase} from '../erreur/erreur-base';

@Component({
  selector: 'app-erreur-connexion-refusee',
  templateUrl: './erreur-connexion-refusee.component.html',
  styleUrls: ['./erreur-connexion-refusee.component.scss']
})
export class ErreurConnexionRefuseeComponent extends ErreurBase {
  readonly buttonRetry: ErrorButtonDescription;
  readonly buttonSignOut: ErrorButtonDescription;

  constructor(private router: Router, private authService: AuthentificationService) {
    super();
    this.buttonRetry = new ErrorButtonDescription().setKey('error.button.connection-refusee.reessayer');
    this.buttonSignOut = new ErrorButtonDescription().setKey('error.button.connection-refusee.deconnexion-cas');
    this.buttonErrorDescriptionList.push(this.buttonRetry, this.buttonSignOut);
  }

  public resolveError($event: ErrorButtonDescription): void {
    switch ($event) {
      case this.buttonRetry :
        this.router.navigate([GuiRoutes.ROOT]);
        break;
      case this.buttonSignOut :
        this.authService.signOut();
        break;
      default : break;
    }
  }
}
